.build-title {
  padding: 30px;
  padding-bottom: 6px;
  text-align: center;
  font-weight: bold;
  font-family: "caros-bold";
  font-size: 20px;
}
.build-head-remove {
  position: absolute;
  top: 33px;
  right: 20px;
  font-size: 20px;
}
.config-drawer-text .MuiTypography-root {
  font-size: 16px !important;
  font-family: "markpro-medium" !important;
}
