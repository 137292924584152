@font-face {
  font-family: "markpro-medium";
  src: local("Markpro"),
    url("./assets/fonts/MarkPro-Medium.woff") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "caros-med";
  src: local("CarosMedium"),
    url("./assets/fonts/CarosMedium.woff") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "caros-bold";
  src: local("CarosBold"),
    url("./assets/fonts/CarosBold.woff") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "markpro-book";
  src: local("MarkPro-Book"),
    url("./assets/fonts/MarkPro-Book.woff") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "markpro-bold";
  src: local("MarkPro-Bold"),
    url("./assets/fonts/MarkPro-Bold.woff") format("truetype");
  font-weight: normal;
}
