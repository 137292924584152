.config-image-container {
  border-right: 1px solid #e4e5e9;
  margin-right: 10px;
  height: 100%;
  width: auto;
}
.config-image-container a img {
  height: 105px;
  width: 113px;
}
.config-item {
  border: 1px solid grey;
  border-top: 4px solid green !important;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  padding: 10px;
  background: #ffffff;
}
.config-item-active {
  border: 3px solid green;
  border-top: 4px solid green !important;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  padding: 10px;
  background: #ffffff;
}

.product-head {
  font-size: 20px;
  font-weight: bold;
  color: black !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.config-item .card {
  margin-bottom: 0px !important;
}
.toggling-btn {
  width: 100%;
  background: #089e42 !important;
  border-color: none !important;
  box-shadow: none !important;
}
.toggling-sub-btn {
  background: #222f8e !important;
  width: 100%;
  opacity: 0.8;
  box-shadow: none !important;
}
