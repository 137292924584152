.ecommerce-contain {
  background: #f7f8fa;
  padding-top: 80px;
}
.tab-active {
  color: #fff !important;
  background: #5e72e4 !important;
  border-radius: 5px;
}
.products-tabs {
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}
.products-tabs:hover {
  background: #5e72e4 !important;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff !important;
}
