/* Resets */

html,
body {
  overflow-x: hidden;
  font-family: "markpro-book";
  height: 100%;
}
.checkout-page .container-fluid .row {
  /* height: 100vh; */
}
.contact-form .row {
  /* height: 100vh; */
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.materialTable-wrap {
  width: 100%;
  overflow-x: scroll !important;
}
.fa {
  font-family: "Font Awesome 5 Free" !important;
}
.search input::placeholder {
  font-family: caros-med !important;
}
.MTableToolbar-title-19 {
  display: none;
}
.MuiTableCell-root {
  font-family: markpro-medium !important;
}

.subscription-plan {
  background-color: #fff;
  padding: 24px 0 40px 0;
  border-radius: 4px;
  box-shadow: 1px 0px 10px rgba(173, 184, 216, 0.2);
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.subscription-plan p {
  font-size: 14px;
  font-family: "markpro-book", sans-serif;
  padding: 24px 35px;
}
.subscription-txt h1 {
  font-family: "caros-med", sans-serif;
  font-size: 20px;
  padding-left: 33px;
  margin-bottom: 18px;
}
.subscription-txt.heading {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.plan-option {
  display: flex;
}
.plan-option .form-check.form-check-inline input {
  margin: 11px 13px 0 0;
  border-color: #212f8e;
}
.wrapper-dash {
  background: #ffffff;
}
.wrapper-dash-logout {
  background: #ffffff !important;
}
span.month {
  font-size: 18px;
  font-family: "markpro-med", sans-serif;
  color: #212f8e;
  margin-right: 9px;
}

span.price {
  font-family: "markpro-bold", sans-serif;
  font-size: 24px;
  color: #212f8e;
  margin-right: 8px;
  display: inline-block;
  transform: translateY(2px);
}

span.plan-des {
  font-size: 12px;
  font-family: "markpro-book", sans-serif;
  color: #212f8e;
  display: inline-block;
  transform: translateY(-2px);
}

.plan-option .form-check-input:checked[type="radio"],
.select-method .form-check-input:checked[type="radio"] {
  background-image: url(../assets//images/check.svg) !important;
  background-size: 8px;
  border: 0;
}
.plan-option .form-check.form-check-inline {
  border: 1px solid #13214a;
  padding: 6px 40px 6px 16px;
  border-radius: 4px;
}

.plan-option .form-check.form-check-inline input {
  margin: 11px 13px 0 0;
  border-color: #212f8e;
}

.plan-option {
  padding-left: 37px;
}

p {
  color: #151a1e;
  margin: 0;
}
.upgrade-btn {
  margin-left: 37px;
}
.upgrade-btn.plan {
  display: flex;
}
.upgrade-btn a {
  color: #fff;
  background-color: #099e43;
  display: block;
  padding: 12px 0;
  width: 100%;
  max-width: 172px;
  text-align: center;
  border-radius: 4px;
  margin-top: 24px;
  border: 1px solid #099e43;
  font-size: 15px;
  font-family: "markpro-medium";
  text-decoration: none;
}

.upgrade-btn a:hover {
  background-color: #212f8e;
  border: 1px solid #212f8e;
  color: #fff;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul li {
  text-decoration: none;
  list-style: none;
}

a {
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
}
.dashboard-table-wrap-inner .quotes-action {
  width: 120px !important;
  text-align: center;
}
.quotes-action {
  width: 120px !important;
  text-align: center;
}

.quotes-action a {
  font-family: "markpro-bold", sans-serif;
  font-size: 11px;
  color: #212f8e;
  padding: 6px 23px;
  border: 1px solid #212f8e;
  border-radius: 4px;
  text-decoration: none;
}
.quotes-action a:hover {
  background: #212f8e;
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "caros-bold", sans-serif;
  color: #151a1e;
  margin: 0;
}

.rc-pagination-item {
  display: inline-block;
  min-width: 58px;
  height: 36px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 4px;
  color: #8a8c8f;
  font-weight: bold;
}
.logedin-nav {
  background: #f7f8fa !important;
  padding: 10px 0px 10px 8px !important;
  box-shadow: 0px 2px 10px rgb(8 39 89 / 10%) !important;
  color: #9d9d9d;
}
.logout-nav {
  background: #ffffff;
  color: #9d9d9d;
}
.makeStyles-content-19 {
  padding: 0 !important;
}

.logedin-nav .config-login-toggler {
  border: none !important;
  outline: none !important;
  margin-right: auto;
}
.logedin-nav .config-login-toggler:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}
.article-padd {
  padding: 120px 0 120px 0;
}

.article-padd-half {
  padding: 60px 0 60px 0;
}
button:focus {
  outline: none;
  outline-color: transparent;
  box-shadow: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* custom classes */

.light-bg-color {
  background-color: #f7f8fa;
}

.dark-bg-color {
  background-color: #13214a;
}

.green-btn {
  font-family: "markpro-medium", sans-serif;
  font-size: 15px;
  color: #ffffff;
  background-color: #099e43;
  border-radius: 4px;
  display: block;
  max-width: max-content;
  padding: 10px 30px;
  text-decoration: none;
}

.green-btn:hover {
  color: #ffffff;
  background-color: #13214a;
}

.table-items {
  /* font-weight: "bold"; */
  /* font-size: "10px"; */
  /* padding-bottom: "4px"; */
}

.table-text {
  font-size: "12px";
  color: "#4f4e4e";
}

.card-toggle-menu {
  position: absolute;
  will-change: transform;
  top: 10px !important;
  left: -25px !important;
  padding: 10px 40px 6px 10px !important;
  min-width: auto !important;
  background: #222f8e !important;
  /* transform: translate3d(-76px, 36px, 0px); */
}
.card-toggle-menu-btn {
  background: transparent !important;
  border: none !important;
}
.card-toggle-menu-btn:hover {
  background: transparent !important;
  border: none;
}
.card-toggle-menu .dropdown-item a {
  padding: 0 !important;
  text-decoration: none !important;
  color: white;
}
.card-toggle-menu .dropdown-item:hover,
.card-toggle-menu .dropdown-item:focus {
  background: none !important;
}

.shadded-bg {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.sec-title {
  font-family: "markpro-medium", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.main-content-inner .col-lg-4.col-md-12 {
  padding-right: 0;
}

.main-content-inner .col-lg-4.col-md-12:last-child {
  padding-right: calc(var(--bs-gutter-x) / 2);
}

/*======================
@@@ Home css starts @@@ 
======================*/

/* header/navbar */

.navbar-expand-lg .navbar-nav .nav-link {
  font-family: "markpro-medium" !important;
  font-size: 15px;
  color: #151a1e;
}
.plan-option .form-check.form-check-inline {
  border: 1px solid #13214a;
  padding: 6px 40px 6px 16px;
  border-radius: 4px;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

/* #flexibility {
  height: 800px !important;
  background-color: black;
}

#analytics {
  height: 800px !important;
  background-color: black;
}

#managment {
  height: 800px !important;
  background-color: black;
}

#automation {
  height: 800px !important;
  background-color: black;
} */

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.animated-content {
  position: "absolute";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: "40px";
  text-align: center;
}

.navbar-nav {
  font-family: "markpro-medium" !important;
}
.cartItems-count {
  background: #089e42;
  color: white;
  position: absolute;
  top: -2px;
  right: -13px;
  border-radius: 100%;
  text-align: center;
  height: 17px;
  width: 17px;
  font-size: 13px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
  color: #099e43 !important;
}

.navbar-expand-lg .navbar-nav .nav-link.active {
  color: #099e43 !important;
}

.navbar-expand-lg .navbar-nav .nav-item:not(:last-child) {
  margin-right: 34px;
}

.navbar-expand-lg .nav-btn .green-btn {
  padding: 11px 30px 7px;
}

.navbar.navbar-expand-lg {
  padding: 35px 0;
}
.shadded-bg {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  padding: 10px;
  width: 100%;
  min-width: 150px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px rgb(28 49 104 / 10%);
  border-radius: 4px;
}

.dropdown-content ul li:not(:last-child) {
  margin-bottom: 5px;
}
.config-login-toggler {
  height: 22px;
  padding-bottom: 2px;
}
.profile-select-menu .MuiPopover-paper {
  margin-top: 29px;
  /* left: 1554px !important; */
  background: #222f8e;
  color: white;
  border-radius: 0;
  padding: 0 3px 0;
  top: 32px !important;
}
.MuiMenuItem-root {
  font-family: markpro-medium !important;
  font-size: 15px !important;
}

.dropdown-content a {
  font-family: "markpro-medium", sans-serif;
  font-size: 13px;
  color: #151a1e;
}

.dropdown-content a:hover {
  color: #099e43;
}

.dashboard-drawer .MuiDrawer-paper {
  top: 60px !important;
  /* z-index: -1; */
}
.dashboard-drawer .MuiDrawer-paper {
  font-family: markpro-medium !important;
  font-size: 15px;
}
.dashboard-drawer .MuiDrawer-paper .makeStyles-drawerHeader-8 {
  display: none;
}
.dashboard-drawer .MuiDrawer-paper .MuiList-padding {
  padding-top: 30px !important;
}
.dashboard-drawer .MuiDrawer-paper .MuiList-padding .MuiListItemIcon-root {
  min-width: 33px !important;
}
.dashboard-drawer
  .MuiDrawer-paper
  .MuiList-padding
  .dashboard-drawer
  .makeStyles-content-9 {
  padding: 0;
}
.makeStyles-content-9 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.dashboard-drawer .makeStyles-content-33 {
  padding: 0;
}
.inactive-lists-item:hover {
  color: green !important;
  border-left: 3px solid green;
  fill: green;
}

.dashboard-drawer .MuiDrawer-paper .MuiList-padding .Mui-selected {
  color: green !important;
  border-left: 3px solid green;
  fill: green;
}
.dashboard-drawer main {
  padding: 0 !important;
  width: 100%;
}

.inactive-lists-item svg:hover {
  fill: green !important;
}
.dashboard-content-wrap {
  padding-top: 107px;
}
.dashboard-content-wrap .main-content-inner {
  padding: 20px 30px;
  overflow: auto;
  /* height: calc(100vh - 88px); */
}
.dashboard-head-wrap-build-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
  min-height: 52px;
  background-color: #f5f8ff;
  border-bottom: 1px solid #ccd9e8;
  position: fixed;
  width: 100%;
  z-index: 998;
  top: 60px;
}
.dashboard-head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
  min-height: 46px;
  background-color: #f5f8ff;
  border-bottom: 1px solid #ccd9e8;
  position: fixed;
  width: 84%;
  z-index: 998;
  top: 60px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.cart-controls input[type="number"] {
  -moz-appearance: textfield;
}
.cart-controls {
  display: flex;
}
.cart-controls input {
  padding-left: 15px;
  padding-right: 3px;
  width: 43px;
  border: 0;
  font-size: 17px;
  outline: none;
}
.cart-controls .plus-button {
  font-weight: bolder;
  background: #ffffff;
  color: green;
  cursor: pointer;
  /* margin-top: 5px; */
  border: 0;
  background: transparent;
  font-size: 10px;
}
.cart-controls .minus-button {
  padding: 3px;
  font-weight: bolder;
  background: #ffffff;
  color: red;
  cursor: pointer;
  /* margin-top: 5px; */
  border: 0;
  background: transparent;
  font-size: 10px;
}
.card-info span {
  font-size: 30px;
  line-height: normal;
  transform: translateY(-2px);
  font-family: "markpro-bold", sans-serif;
  display: inline-block;
  margin-right: 5px;
}
.card-info.payment_methods {
  margin-top: 50px;
}
.dashboard-stats-wrap {
  padding: 0 4px;
}
.dashboard-head-title h1 {
  font-size: 14px;
  color: #2f353a;
  line-height: 0;
  padding-top: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
  position: absolute;
  left: 0;
  top: 33px;
  z-index: 99;
}

/* main banner */

.main-banner-text-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}
.profile-select-menu-mb .MuiPopover-paper {
  width: 90%;
  max-width: 90%;
  left: 5% !important;
  top: 119px !important;
  z-index: -1;
  padding-top: 8px !important;
  padding-bottom: 0 !important;
  background: #222f8e;
  color: white;
  margin: 0 !important;
  border-radius: 0;
  /* height: 120px; */
  /* height: 10px; */
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: green !important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: green !important;
}
.config-stepper {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%) !important;
  border-radius: 4px 4px 0px 0px;
  font-family: "caros-bold", sans-serif !important;
}
.config-stepper
  .MuiStep-root
  .MuiStepLabel-root
  .MuiStepLabel-labelContainer
  .MuiTypography-root {
  font-family: "caros-bold", sans-serif !important;
  /* color: #1f2327 !important; */
  font-size: 16px;
}
.profile-select-menu-mb {
  z-index: 1300 !important;
}
.profile-select-menu-mb .MuiPopover-paper .MuiMenu-list .MuiMenuItem-root {
  display: block;
  padding: 0 17px !important;
  margin: 0 !important;
  min-height: 30px;
}
.profile-select-menu-mb .MuiList-padding {
  padding: 0 !important;
}
.profile-select-menu-mb-top {
  z-index: 1299 !important;
}
.profile-select-menu-mb-top .MuiPopover-paper {
  width: 90%;
  max-width: 90%;
  left: 5% !important;
  top: 57px !important;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: none;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
}
.profile-select-menu-mb-top .MuiPopover-paper .MuiMenu-list .MuiMenuItem-root {
  display: inline-block;
  padding: 0 2px;
}

.main-banner-text {
  max-width: 694px;
  width: 100%;
}

.main-banner-text h1 {
  font-size: 42px;
  line-height: 62px;
  margin-bottom: 22px;
}

.main-banner-text p {
  font-size: 20px;
  line-height: 35px;
  max-width: 552px;
  width: 100%;
  margin-bottom: 30px;
}
.active-carousel-thumb {
  border: 1px solid green;
}

.pop-model .modal-dialog-centered {
  outline: none;
}

.main-banner-links {
  display: flex;
  align-items: center;
}

.banner-btn {
  margin-right: 34px;
}

.banner-btn .green-btn {
  padding: 10px 50px;
}

.external-link a {
  font-family: "markpro-bold", sans-serif;
  font-size: 16px;
  color: #212f8e;
  display: flex;
  align-items: center;
  text-decoration: underline;
  max-width: max-content;
}

.external-link a img {
  margin-left: 5px;
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}

.external-link a:hover img {
  transform: translateX(5px);
}

.main-banner-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* marketplace section */

.marketplace-sec-head-wrap {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 60px;
}
.profile-inner {
  width: 40%;
}
.person-icon-profile {
  margin-left: -6px;
}

.marketplace-sec-head-text {
  max-width: 610px;
  width: 100%;
}

.marketplace-sec-head-text h2 {
  font-size: 40px;
}

.marketplace-product-single-wrap {
  display: block;
  min-height: 220px;
  padding: 40px 10px 17px;
  margin-bottom: 24px;
}

.marketplace-product-single-wrap:hover {
  box-shadow: 0 2px 12px rgb(0 0 0 / 18%);
}

.marketplace-product-single-wrap-img {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 142px;
  max-height: 142px;
}
/* #containerSelectorFocus div div div {
  top: 50px !important;
} */

.marketplace-product-single-wrap-text p {
  font-family: "markpro-medium", sans-serif;
  font-size: 13px;
  text-align: center;
}

.marketplace-product-single-wrap.single-img {
  /* height: calc(100% - 24px); */
  padding: 90px 20px 20px;
  margin-bottom: 0;
}

.marketplace-product-single-wrap.single-img
  .marketplace-product-single-wrap-img {
  min-height: 330px;
  max-height: 330px;
}

.marketplace-product-single-wrap.single-img
  .marketplace-product-single-wrap-text
  p {
  font-size: 18px;
}

/* configurator section */

.configurator-home-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 4px;
  padding: 60px;
  width: 100%;
}

.configurator-home-text-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.configurator-home-text {
  max-width: 500px;
  width: 100%;
  padding-right: 30px;
}

.configurator-home-text h2 {
  font-size: 40px;
  color: #ffffff;
  margin-bottom: 20px;
}

.configurator-home-text p {
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

/* marketplace section */

.features-home-head {
  max-width: 670px;
  width: 100%;
  margin: 0 auto 60px;
  text-align: center;
}

.card-bdy {
  background-color: #fff;
  padding: 10px 0 30px;
  box-shadow: 1px 0px 10px rgb(173 184 216 / 20%);
  border-radius: 4px;
}
.card-bdy.new {
  border: 1px solid #eeebeb;
}

.success-payment h2 {
  color: #222f8e;
}
.product-showcase-container {
  max-height: 95vh;
  overflow: scroll;
  overflow-x: hidden;
}
.product-showcase-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.product-showcase-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.error_section {
  padding: 4rem 2rem;
  text-align: left;
  clear: both;
}

.section .error {
  font-size: 150px;
  color: black;
}

.page {
  position: relative;
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home {
  display: inline;
  border: 2px solid #222;
  color: #222;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.back-home:hover {
  background: #222;
  color: #ddd;
}
.card-bdy.new hr {
  margin: 10px 0 3px;
}
.features-home-head h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

.features-home-configurator,
.features-home-ar {
  text-align: center;
}

.features-home-configurator-text,
.features-home-ar-text {
  margin: 24px 0;
}

.features-home-main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* automated processing section */

.app-equip-accord
  .MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-expandIcon
  .MuiIconButton-label
  .MuiSvgIcon-root {
  font-size: 2rem !important;
  color: black !important;
}

.automated-home-head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 60px;
}

.automated-home-text h2 {
  font-size: 40px;
}

.automated-process-single {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 212px;
  margin-bottom: 24px;
}
.subhead-components {
  color: #212529;
}
.automated-process-single-img {
  margin-bottom: 20px;
}

.automated-process-single-text p,
.automated-home-feature-single p {
  font-family: "markpro-bold", sans-serif;
  font-size: 18px;
}

.automated-home-feature-single {
  min-height: 65px;
  display: flex;
  align-items: center;
  padding: 19px 20px 19px 45px;
  margin-bottom: 24px;
}

.automated-home-feature-single p img {
  margin-right: 16px;
}

.automated-home-wrap
  .automated-home-features-wrap:last-child
  .automated-home-feature-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19.5px 20px 18px 45px;
}
.quotes-action button {
  font-family: "markpro-bold", sans-serif;
  font-size: 11px;
  color: #212f8e;
  padding: 6px 23px;
  border: 1px solid #212f8e;
  border-radius: 4px;
  text-decoration: none;
  background: #ffffff;
}
.charge-payment-modal-title .MuiTypography-root {
  font-family: "markpro-medium", sans-serif;
}
.charge-payment-modal-amounts {
  font-family: "markpro-medium", sans-serif;
  font-size: 16px;
  color: black;
}

.quotes-action button:hover {
  font-family: "markpro-bold", sans-serif;
  font-size: 11px;
  color: #ffffff;
  padding: 6px 23px;
  border: 1px solid #212f8e;
  border-radius: 4px;
  text-decoration: none;
  background: #212f8e;
}

/*======================
@@@ login css starts @@@ 
======================*/

.needs-validation {
  max-width: 508px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 6px 32px rgb(179 188 211 / 27%);
  border-radius: 4px;
  margin: 200px auto;
}
.profile-needs-validation {
  margin-left: 20px;
}
.profile-needs-validation .needs-validation {
  max-width: 100%;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: none;
  border-radius: 4px;
  margin: 0;
}
.profile-needs-validation .needs-validation .form-outline {
  max-width: 100%;
}
.profile-needs-validation .needs-validation .heading_txt_login {
  float: left;
}

.quote-by-id-container {
  max-height: 500px;
  overflow: scroll;
}

.needs-validation .form-outline {
  max-width: 428px;
  margin: 0 auto;
  padding-bottom: 25px;
}

.forget-pass {
  display: flex;
  justify-content: space-between;
  max-width: 420px;
  margin: 0 auto;
  width: 100%;
}

.resend-otp {
  display: flex;
  justify-content: flex-end;
  max-width: 420px;
  margin: 0 auto;
  width: 100%;
}

.config-options-div {
  max-height: 70vh;
  overflow: scroll;
}
.configurator-drawer .MuiPaper-root {
  max-width: 330px;
  width: 100%;
}

.resend-code {
  text-align: center;
}

.resend-code p a {
  color: #212f8e;
  font-size: 18px;
  font-family: "markpro-book", sans-serif;
  border-bottom: 1px solid #212f8e;
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  text-decoration: none !important;
}

.needs-validation button.btn.btn-primary {
  display: block;
  width: 100%;
  max-width: 428px;
  padding: 12px 0;
  background-color: #099e43;
  border: 1px solid #099e43;
  margin: 30px auto 40px auto;
  box-shadow: 0px 26px 32px rgba(179, 188, 211, 0.27);
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}

.needs-validation button.btn.btn-primary1 {
  display: block;
  color: white;
  width: 100%;
  max-width: 428px;
  padding: 7px 0;
  background-color: #212f8e;
  border: 1px solid #212f8e;
  margin: 30px auto 40px auto;
  box-shadow: 0px 26px 32px rgba(179, 188, 211, 0.27);
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}
.needs-validation button.btn.btn-primary2 {
  display: block;
  color: #212f8e;
  width: 100%;
  max-width: 428px;
  padding: 7px 0;
  background-color: white;
  border: 2px solid #212f8e;
  margin: 30px auto 40px auto;
  box-shadow: 0px 26px 32px rgba(179, 188, 211, 0.27);
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}

.create-acc {
  text-align: center;
  padding: 30px 0;
}

.heading_txt_login {
  text-align: center;
  padding: 40px 0 56px 0;
}

.shipping-form input::placeholder {
  font-size: 18px;
  font-family: "markpro-med", sans-serif;
  color: #878787;
}

.needs-validation .form-control {
  padding: 12px 24px;
}

.needs-validation .form-outline input:focus,
.shipping-form .form-outline .form-control:focus {
  box-shadow: none;
  border: 1px solid #099e43;
}
.needs-validation .form-outline-danger input:focus,
.shipping-form .form-outline .form-control:focus {
  box-shadow: none;
  border: 1px solid red;
}
.shipping-form .form-outline-danger input:focus,
.shipping-form .form-outline .form-control:focus {
  box-shadow: none;
  border: 1px solid red;
}

.needs-validation .form-outline input {
  border: 1px solid #eeebeb;
}

.needs-validation .form-outline-danger input {
  border: 1px solid red;
}
.shipping-form .form-outline-danger input {
  border: 1px solid red;
}
.contact_details .form-outline-danger {
  max-width: 304px;
  width: 100%;
}
.form-outline-danger {
  margin-bottom: 20px;
  padding: 5px 0;
}
.login .nav-btn {
  display: flex;
  align-items: center;
}

.needs-validation .form-outline input::placeholder {
  color: #878787;
  font-size: 18px;
  font-family: "markpro-med", sans-serif;
}
.checkbox-rember .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.checkbox-rember label {
  font-size: 18px;
  color: #151a1e;
  font-family: "markpro-book", sans-serif;
  padding-left: 5px;
}

.forget-pass p a {
  color: #212f8e;
  font-size: 18px;
  font-family: "markpro-book", sans-serif;
  border-bottom: 1px solid #212f8e;
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  text-decoration: none !important;
}

.resend-otp p a {
  color: #212f8e !important;
  font-size: 18px;
  font-family: "markpro-book", sans-serif;
  border-bottom: 1px solid #212f8e;
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  text-decoration: none !important;
}

.create-acc p {
  color: #717f92;
  font-size: 18px;
  font-family: "markpro-book", sans-serif;
}

.create-acc a {
  font-family: "caros-bold", sans-serif;
  color: #212f8e;
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  padding-left: 5px;
  text-decoration: none;
}

.forget-pass p a:hover {
  color: #099e43;
  border-bottom: 1px solid #099e43;
}

.needs-validation button.btn.btn-primary:hover {
  background-color: #212f8e;
  border: 1px solid #212f8e;
}

.create-acc a:hover {
  color: #099e43;
}

/*======================
@@@ login css End @@@ 
======================*/

/*======================
@@@ Checkout Css Start @@@ 
======================*/

.contact-info h1 {
  font-family: "caros-med", sans-serif;
  font-size: 18px;
  margin-top: 48px;
}

.flexibilty-section {
  padding-top: 100px;
  background: #ffffff;
}
.managment-section {
  /* padding-top: 100px; */
  background: #ffffff;
}
.user-acc {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.contact-email {
  display: flex;
  align-items: center;
  margin: 25px 0 48px;
}

.user-email {
  padding-left: 16px;
}

.user-email p {
  font-family: "markpro-med", sans-serif;
  font-size: 18px;
  color: #878787;
}

.user-email a {
  color: #212f8e;
  font-size: 14px;
  font-family: "caros", sans-serif;
}

.user-email a:hover {
  color: #099e43;
}

.shipping-address h1 {
  font-size: 18px;
  font-family: "caros-med", sans-serif;
  margin-bottom: 24px;
}

.contact_details {
  display: flex;
  justify-content: space-between;
}

.contact_details .form-outline {
  max-width: 304px;
  width: 100%;
}

.form-outline {
  margin-bottom: 20px;
  padding: 5px 0;
}

.shipping-form .form-outline .form-control {
  border: 1px solid #eeebeb;
  padding: 12px 17px;
}
.shipping-form .form-outline-danger .form-control {
  /* border: 1px solid #eeebeb; */
  padding: 12px 17px;
}

.payment_method h1 {
  font-family: "caros-med", sans-serif;
  font-size: 18px;
  margin-bottom: 8px;
}

.payment_method p {
  color: #8b8e90;
  font-family: "markpro-book", sans-serif;
  font-size: 14px;
  margin-bottom: 24px;
}

.billing-option {
  border: 1px solid #eeebeb;
  border-radius: 4px;
}

.billing-option .form-check {
  padding: 14px 0 14px 50px;
}

.billing-option .form-check-input:checked[type="radio"],
.select-method .form-check-input:checked[type="radio"] {
  /* background-image: url(../images/check.svg) !important; */
  background-size: 8px;
  border: 0;
}

.select-method .form-check-input[type="radio"] {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.checkout {
  display: flex;
  align-items: center;
}

.check-out h4 {
  font-size: 16px;
  font-family: "markpro-book", sans-serif;
  max-width: 220px;
  width: 100%;
}

.check-out p {
  font-size: 14px;
  font-family: "markpro-book", sans-serif;
  color: #8b8e90;
  margin-top: 16px;
}

.checkout-inner {
  border: 0.5px solid #c8c8c8;
  background-color: #fff;
  /* padding: 35px 36px; */
  border-radius: 4px;
  position: relative;
  height: 150px;
  width: 150px;
}
.data-analysis-bg {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.13) 30.03%,
    #f7f8fa 100%
  );
  border-bottom: 1px solid #e1e1e1;
}

.checkout-product-info {
  /* border-bottom: 1px solid #d6dbee; */
  /* padding: 32px 3px; */
}

.product-summary {
  /* background-color: #f7f8fa; */
  padding: 48px 60px 48px 48px;
  height: 100%;
}

.order-summary {
  margin-bottom: 18px;
}

.cost {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}

.cost p {
  font-family: "caros", sans-serif;
  font-size: 16px;
  color: #151a1e;
}

.cost.grand-total p {
  font-family: "caros", sans-serif;
  font-size: 24px;
}

p.grand_total_cost {
  font-family: "markpro-bold", sans-serif !important;
}

.checkout-inner span.product-count {
  background-color: #099e43;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-family: "markpro-bold", sans-serif;
  font-size: 16px;
  position: absolute;
  text-align: center;
  top: -5px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* page Setup */

section.checkout-page .container-fluid {
  padding: 0;
  background: #ffffff;
}

section.checkout-page .col-md-6:first-child {
  max-width: 670px;
  width: 100%;
  margin-left: auto;
  padding-right: 40px;
}

/* End */

.checkout-links {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  margin: 10px 0;
}

.return a {
  color: #212f8e;
  text-decoration: none;
  font-family: "markpro-bold", sans-serif;
  font-size: 16px;
  margin-left: 8px;
}
.return button {
  color: #212f8e;
  text-decoration: none;
  font-family: "markpro-bold", sans-serif;
  font-size: 18px;
  /* margin-left: 8px; */
  border: 0;
  /* cursor: pointer; */
  background: none;
}
.return button:hover {
  color: #212f8e;
  text-decoration: none;
  font-family: "markpro-bold", sans-serif;
  font-size: 18px;
  margin-left: 4px;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
}

.return {
  display: flex;
  align-items: center;
}

.select-method {
  margin-top: 48px;
}

.place-order a {
  background-color: #099e43;
  color: #fff;
  padding: 12px 62px;
  display: block;
  border-radius: 4px;
  text-decoration: none;
}
.place-order button {
  background-color: #099e43;
  color: #fff;
  padding: 8px 30px;
  display: block;
  border-radius: 4px;
  text-decoration: none;
  border: 1px solid #099e43;
}

.place-order a:hover {
  background-color: #212f8e;
  color: #fff;
}
.place-order button:hover {
  background-color: #212f8e;
  color: #fff;
}

.panel-heading h4.panel-title a {
  color: #45495b;
  font-family: "markpro-med", sans-serif;
  font-size: 16px;
}

.panel-body {
  background-color: #f7f8fa;
  padding: 34px 32px;
}

h4.panel-title.second_option {
  border-radius: 4px 4px 0 0;
}

h4.panel-title {
  border: 1px solid #eeebeb;
  padding: 14px 24px;
  border-radius: 0 0 4px 4px;
  display: flex;
  align-items: center;
}

.contact-info {
  margin-top: 40px;
}

.visa {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.tag {
  padding: 0 20px;
}

.tag p {
  color: #fff;
  background-color: #212f8e;
  border-radius: 61px;
  width: 100%;
  max-width: 76px;
  padding: 6px 0;
  font-size: 10px;
  text-align: center;
  font-family: "markpro-bold", sans-serif;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.card-bdy {
  background-color: #fff;
  padding: 10px 0 30px;
  box-shadow: 1px 0px 10px rgb(173 184 216 / 20%);
  border-radius: 4px;
}

.card-info {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: baseline;
}

.card-info p {
  margin-bottom: 0;
  color: #151a1e;
  font-family: "markpro-book", sans-serif;
  font-size: 14px;
  margin-top: -15px;
}

.card-info span {
  font-size: 30px;
  line-height: normal;
  transform: translateY(-2px);
  font-family: "markpro-bold", sans-serif;
  display: inline-block;
  margin-right: 5px;
}

p.date {
  font-size: 12px;
  font-family: "markpro-book", sans-serif;
  color: rgb(21 26 30 / 0.65);
}

.payment_method {
  margin-top: 40px;
}

.card-opt .form-outline input {
  border: 0;
}

.card-opt .form-outline {
  margin-bottom: 0;
  padding: 0;
}

.card-info.detail-info .form-outline input {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
}

.card-info.detail-info {
  justify-content: normal;
}

.card-info.detail-info .form-outline {
  padding: 0;
}

.card-info.detail-info .form-control:focus {
  border-color: #eeebeb;
  box-shadow: none;
}

.card-info.detail-info .form-control {
  border-color: #eeebeb;
}

.card-opt img {
  position: absolute;
  right: 15px;
  top: 13px;
}

.card-opt .form-outline input {
  border-bottom: 1px solid #eeebeb;
  border-radius: 0;
  padding-left: 24px;
  padding: 10px 24px 7px;
}

.card-opt {
  position: relative;
}

.card-info.detail-info .form-outline {
  margin-bottom: 0;
  max-width: 240px;
  width: 100%;
}

.form-outline.cvc input {
  border: 0;
}

.card-info.detail-info .form-outline input {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
  padding: 9px 4px;
  max-width: 230px;
  width: 100%;
}

.card-info.detail-info {
  position: relative;
  align-items: center;
}

.card-info.detail-info img {
  position: absolute;
  right: 15px;
}

.card-bdy.new {
  border: 1px solid #eeebeb;
}

.new {
  padding: 0;
}

.new-card-heading h1 {
  font-family: "markpro-bold", sans-serif;
  font-size: 14px;
  color: #45495b;
  margin-top: 20px;
}

.card-opt p {
  margin: 0;
  font-family: "markpro-med", sans-serif;
  font-size: 18px;
}

.card-info.detail-info p {
  font-size: 18px;
  font-family: "markpro-med", sans-serif;
  color: #878787;
  padding-top: 10px;
}

.card-bdy.new hr {
  margin: 10px 0 3px;
}

.checkbox-rember .form-check-input:checked[type="checkbox"] {
  border: 0;
}

.card-opt .form-outline input::placeholder,
.card-info.detail-info input::placeholder {
  font-size: 18px;
  font-family: "markpro-med", sans-serif;
  color: #878787;
}

.billing-option .form-check label.form-check-label {
  font-family: "markpro-med", sans-serif;
  font-size: 16px;
  color: #45495b;
}

.order-summary h1 {
  font-size: 32px;
}

.logo-mbl {
  display: none;
}

/*======================
@@@ Media Querry Start @@@ 
======================*/

@media all and (max-width: 1545px) {
  section.checkout-page .col-md-6:first-child {
    max-width: 640px;
    width: 50%;
    margin-left: unset;
    padding-left: 40px;
  }
}
@media all and (max-width: 1320px) {
  section.checkout-page .col-md-6:first-child {
    max-width: 670px;
    width: 50%;
    margin-left: unset;
    padding-left: 40px;
  }
}

@media all and (max-width: 1240px) {
  .contact_details .form-outline {
    max-width: 264px;
  }
  section.checkout-page .col-md-6:first-child {
    max-width: 640px;
    width: 50%;
    margin-left: unset;
    padding-left: 70px;
  }
  .contact_details .form-outline:first-child {
    margin-right: 10px;
  }
}

@media all and (max-width: 992px) {
  .checkout-product-info {
    padding: 32px 0px;
  }
  .card-opt .form-outline input::placeholder,
  .card-info.detail-info input::placeholder {
    font-size: 14px;
  }
  .user-email p {
    font-size: 16px;
  }
  .dashboard-head-wrap {
    top: 70px;
  }
  .profile-select-menu .MuiPopover-paper {
    margin-top: 38px;
    background: #222f8e;
    color: white;
    border-radius: 0;
    padding: 0 3px 0;
    top: 32px !important;
  }
}

@media all and (max-width: 823px) {
  .user-email p {
    font-size: 14px;
  }
  .place-order a {
    padding: 12px 42px;
  }
  .checkout-inner {
    padding: 20px;
  }
  .card-opt img {
    display: none;
  }
}

@media all and (max-width: 768px) {
  section.checkout-page .col-md-6:first-child {
    max-width: 100%;
    width: 100%;
    padding: 0 30px;
    order: 2;
  }
  .profile-needs-validation {
    margin-left: 10px;
  }
  .config-options-div {
    height: 30vh !important;
  }
  .config-stepper
    .MuiStep-root
    .MuiStepLabel-root
    .MuiStepLabel-labelContainer
    .MuiTypography-root {
    font-size: 14px;
  }
  .contact_details .form-outline {
    max-width: 324px;
  }
  section.checkout-page .col-md-6:last-child {
    width: 100%;
    order: 1;
  }
  .contact_details .form-outline {
    max-width: 100%;
  }
  .card-opt img {
    display: block;
  }
  .order-summary {
    margin-bottom: 0;
  }
  .order-summary h1 {
    font-size: 22px;
  }
  .contact-info img {
    display: none;
  }
  .logo-mbl {
    display: block;
    margin-bottom: 20px;
  }
}

@media all and (max-width: 475px) {
  .needs-validation {
    padding: 0 20px;
  }
  .forget-pass p a,
  .checkbox-rember label,
  .create-acc p {
    font-size: 14px;
  }
  .heading_txt_login {
    padding: 25px 0 25px 0;
  }
  .needs-validation .form-outline {
    padding-bottom: 20px;
  }
  .heading_txt_login.pass h1 {
    font-size: 20px;
  }
  .heading_txt_login p {
    font-size: 14px;
    padding-top: 14px;
  }
  .user-email p {
    font-size: 14px;
  }
  .shipping-form .form-control::placeholder {
    font-size: 14px;
  }
  .return {
    margin-bottom: 10px;
  }
  .checkout-links {
    display: block;
    text-align: center;
  }
  .checkout-inner {
    padding: 20px;
  }
  .billing-option .form-check label {
    display: inline-block;
    font-size: 14px;
  }
  .form-outline {
    margin-bottom: 10px;
  }
  .product-summary {
    padding: 20px;
  }
  .order-summary {
    margin-bottom: 0;
  }
  .select-method {
    margin-top: 20px;
  }
  .card-opt img {
    display: none;
  }
}

/*======================
@@@ Media Querry End @@@ 
======================*/

/*======================
@@@ Checkout Page End @@@ 
======================*/

/*======================
@@@ Forget Password Css starts @@@ 
======================*/

.heading_txt_login p {
  font-size: 16px;
  font-family: "markpro-book", sans-serif;
  color: #717f92;
  max-width: 431px;
  margin: 0 auto;
  padding-top: 24px;
}

.heading_txt_login.pass {
  padding: 40px 0 32px;
}

.needs-validation.pass {
  max-width: 508px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 6px 32px rgb(179 188 211 / 27%);
  border-radius: 4px;
  margin: 85px auto;
  padding-bottom: 10px;
}

.heading_txt_login.pass h1 {
  font-size: 32px;
  font-family: "caros-bold", sans-serif;
}

.needs-validation.pass button.btn.btn-primary {
  margin: 38px auto 40px auto;
}

.needs-validation.pass .form-outline {
  padding-bottom: 0px;
}

/*======================
@@@ Forget Password Css End @@@ 
======================*/

/* data analysis section */

.data-analysis-wrap {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.13) 30.03%,
    #f7f8fa 100%
  );
  border-bottom: 1px solid #e1e1e1;
}

.order-analytics-single-title h3 {
  font-family: "caros-med", sans-serif;
  font-size: 20px;
}

.data-analysis-text-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

.data-analysis-text {
  max-width: 475px;
  width: 100%;
}

.data-analysis-text h2 {
  margin-bottom: 20px;
}

.data-analysis-text p:not(:last-child) {
  margin-bottom: 15px;
}

.data-analysis-wrap .row:nth-child(2) {
  margin-top: 150px;
}

.data-analysis-wrap .row:nth-child(2) .data-analysis-text {
  padding: 0 0 0 55px;
}

/* explore more section */

.explore-home-wrap .container {
  border-bottom: 1px solid #e1e1e1;
  padding: 70px 0;
}
.opt {
  height: 100%;
  min-height: 144px;
  position: relative;
}
.visa {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.opt .visa {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px;
}
.tag {
  padding: 0 20px;
}

.tag p {
  color: #fff;
  background-color: #212f8e;
  border-radius: 61px;
  width: 100%;
  max-width: 76px;
  padding: 6px 0;
  font-size: 10px;
  text-align: center;
  font-family: "markpro-bold", sans-serif;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.card-info {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: baseline;
}

.card-info p {
  margin-bottom: 0;
  color: #151a1e;
  font-family: "markpro-book", sans-serif;
  font-size: 14px;
  margin-top: -15px;
}

.card-info span {
  font-size: 30px;
  line-height: normal;
  transform: translateY(-2px);
  font-family: "markpro-bold", sans-serif;
  display: inline-block;
  margin-right: 5px;
}
p.date {
  font-size: 12px;
  font-family: "markpro-book", sans-serif;
  color: rgb(21 26 30 / 0.65);
}

.payment_method {
  margin-top: 40px;
}

.card-opt .form-outline input {
  border: 0;
}

.card-opt .form-outline {
  margin-bottom: 0;
  padding: 0;
}

.card-info.detail-info .form-outline input {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
}

.card-info.detail-info {
  justify-content: normal;
}

.card-info.detail-info .form-outline {
  padding: 0;
}

.card-info.detail-info .form-control:focus {
  border-color: #eeebeb;
  box-shadow: none;
}

.card-info.detail-info .form-control {
  border-color: #eeebeb;
}

.card-opt img {
  position: absolute;
  right: 15px;
  top: 13px;
}

.card-opt .form-outline input {
  border-bottom: 1px solid #eeebeb;
  border-radius: 0;
  padding-left: 24px;
  padding: 10px 24px 7px;
}

.card-opt {
  position: relative;
}

.card-info.detail-info .form-outline {
  margin-bottom: 0;
  max-width: 240px;
  width: 100%;
}

.form-outline.cvc input {
  border: 0;
}

.card-info.detail-info .form-outline input {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
  padding: 9px 4px;
  max-width: 230px;
  width: 100%;
}

.card-info.detail-info {
  position: relative;
  align-items: center;
}

.card-info.detail-info img {
  position: absolute;
  right: 15px;
}
.card-info.detail-info p {
  font-size: 18px;
  font-family: "markpro-med", sans-serif;
  color: #878787;
  padding-top: 10px;
}
.card-opt .form-outline input::placeholder,
.card-info.detail-info input::placeholder {
  font-size: 18px;
  font-family: "markpro-med", sans-serif;
  color: #878787;
}

.explore-home-inner {
  margin-bottom: 60px;
}

.explore-home-single {
  padding: 0 50px 0 0;
}

.explore-home-single img {
  margin-bottom: 16px;
}

.explore-home-single h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.explore-home-btn .green-btn {
  margin: 0 auto 20px;
}

.explore-home-links .external-link a {
  margin: 0 auto;
}

.model-heading-review h1 {
  font-size: 20px;
  font-family: "caros-med", sans-serif;
}

.model-heading-review h1 {
  font-size: 20px;
  font-family: "caros-med", sans-serif;
  margin-left: 80px;
}

.modal-build-single-name {
  margin-left: 18px;
}

.modal-build-single-name p {
  font-family: "markpro-med", sans-serif;
  font-size: 16px;
  color: #151a1e;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cart-icon .model-left-product h4 {
  font-size: 14px;
  font-family: "markpro-book", sans-serif;
  max-width: 160px;
  padding-bottom: 10px;
}

.cart-icon .model-left-product p {
  color: #8b8e90;
  font-size: 12px;
  font-family: "markpro-book", sans-serif;
}
.cart-icon .model-left-inner {
  border: 0.5px solid #c7c6c6;
  border-radius: 4px;
  padding: 14px 15px;
  position: relative;
}
.cart-icon .model-left {
  margin: 24px 0;
}
.modal-build-single-text {
  display: flex;
  align-items: center;
  width: 100%;
}
.cart-icon .modal-body-inner .modal-build-single:not(:last-child) {
  margin-bottom: 0;
}
.modal-body-inner .modal-build-single:not(:last-child) {
  margin-bottom: 30px;
}
.product-details-cart {
  padding: 0 17px;
}
.modal-build-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* global platform section */

.global-home-icons-wrap {
  max-width: 456px;
  width: 100%;
}

.global-home-icons-wrap h2 {
  font-size: 36px;
  margin-bottom: 45px;
}

.global-home-icons {
  display: flex;
  align-items: center;
}

.global-home-icons .global-home-icon-single:not(:last-child) {
  margin-right: 65px;
}

.global-home-icon-single {
  text-align: center;
}

.global-home-icon-single img {
  margin-bottom: 10px;
}

.global-home-text-wrap {
  max-width: 522px;
  width: 100%;
}

.global-home-text-single {
  display: flex;
  align-items: flex-start;
}

.global-home-text-single-icon {
  margin-right: 11px;
}

.global-home-text-wrap .global-home-text-single:not(:last-child) {
  margin-bottom: 60px;
}

.global-home-text-single-text h3 {
  font-size: 24px;
  margin-bottom: 8px;
}

/* secure check wrap */

.secure-check-head .sec-title,
.secure-check-head h2,
.secure-check-text-single-text h3,
.secure-check-text-single-text p {
  color: #ffffff;
}

.secure-check-head h2 {
  font-size: 32px;
}

.secure-check-text-single-text h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.secure-check-inner {
  margin-top: 45px;
}

.secure-check-text-wrap {
  padding: 0 25px;
}

.secure-check-text-wrap .secure-check-text-single:not(:last-child) {
  margin-bottom: 80px;
}

.secure-check-text-single {
  display: flex;
  align-items: flex-start;
}

.secure-check-text-single-icon {
  margin-right: 8px;
}
.profile-cart-dropdown {
  margin-left: 18px;
}
.product-thumb-single {
  height: 100px;
  width: 100px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

/* footer */

footer {
  border-top: 1px solid #e1e1e1;
  position: relative;
  bottom: 0;
}
.order-analytics-wrap {
  margin-top: 4px;
}

.order-analytics-single-period span {
  font-family: "markpro-bold", sans-serif;
  font-size: 14px;
  color: #b2b8c7;
}

.order-analytics-single-period span:hover,
.order-analytics-single-period span.active {
  color: #099e43;
  text-decoration: underline;
  cursor: pointer;
}

.order-analytics-single-period span:not(:last-child) {
  margin-right: 37px;
}
.order-analytics-single-body img {
  width: 100%;
}

.footer-logo {
  display: block;
  max-width: max-content;
  margin-bottom: 20px;
}

.footer-info-wrap p {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 20px;
}
.order-analytics-single {
  padding: 30px;
}
.order-analytics-single-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.footer-social-links {
  display: flex;
  align-items: center;
}

.footer-social-links a {
  display: block;
  max-width: max-content;
  margin: 0 13px 13px 0;
}

.footer-social-links a:hover {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.footer-links-head {
  margin-bottom: 24px;
}

.footer-links-head h4 {
  font-family: "markpro-bold", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

.footer-links {
  max-width: max-content;
  width: 100%;
  margin: 0 auto;
}

.footer-links-list ul li:not(:last-child) {
  margin-bottom: 24px;
}

.footer-links-list ul li a {
  font-size: 14px;
  color: #151a1e;
  text-decoration: none;
}

.footer-links-list ul li a:hover {
  color: #099e43;
}
.footer-links-list ul {
  padding-left: 0px;
}

/* copyright */

.copyright {
  border-top: 1px solid #e1e1e1;
  padding: 16px 15px;
  text-align: center;
  background-color: #fff;
}

.copyright p {
  font-size: 13px;
}

/* HOME MEDIA QUERIES */

@media all and (max-width: 1400px) {
  .marketplace-product-single-wrap {
    min-height: 237px;
  }

  .secure-check-text-single {
    min-height: 134px;
  }
}

@media all and (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-item:not(:last-child) {
    margin-right: 15px;
  }

  .main-banner-text {
    max-width: 470px;
  }

  .main-banner-text h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .marketplace-sec-head-text h2,
  .configurator-home-text h2,
  .features-home-head h2,
  .automated-home-text h2 {
    font-size: 32px;
  }

  .global-home-icons-wrap h2 {
    font-size: 29px;
  }

  .secure-check-head h2 {
    font-size: 32px;
  }

  .secure-check-text-single {
    min-height: 186px;
  }

  .secure-check-text-wrap .secure-check-text-single:not(:last-child) {
    margin-bottom: 50px;
  }

  .automated-home-wrap
    .automated-home-features-wrap:last-child
    .automated-home-feature-single {
    padding: 15px 15px 15px 15px;
    min-height: 92px;
  }
}

@media all and (max-width: 992px) {
  .navbar-light .navbar-toggler {
    border-color: rgb(9 158 67);
  }
  .card-opt .form-outline input::placeholder,
  .card-info.detail-info input::placeholder {
    font-size: 14px;
  }

  .navbar-collapse {
    background: #f7f8fa;
    padding: 10px 20px 20px;
    position: absolute;
    top: 90px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgb(0 0 0 / 18%);
    z-index: 99;
  }

  .dropdown .dropdown-content,
  .dropdown:hover .dropdown-content {
    display: block;
    position: relative;
    top: 0;
    box-shadow: none;
  }

  .main-banner-img-wrap {
    margin-top: 50px;
    padding: 0 20px;
  }

  .marketplace-sec-head-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .marketplace-sec-head-text {
    margin-bottom: 20px;
  }

  .marketplace-product-single-wrap.single-img {
    height: 100%;
    padding: 30px;
    margin-bottom: 0;
  }

  .marketplace-product-single-wrap.single-img
    .marketplace-product-single-wrap-img {
    min-height: unset;
    max-height: unset;
  }

  .configurator-home-img-wrap {
    padding: 40px;
  }

  .configurator-home-img-wrap img {
    max-width: 380px;
    width: 100%;
    aspect-ratio: 1/1;
  }

  .configurator-home-text-wrap {
    justify-content: flex-start;
    margin-top: 30px;
  }

  .features-home-ar {
    margin-top: 70px;
  }

  .marketplace-product-single-wrap-text p {
    font-size: 15px;
  }

  .features-home-configurator {
    margin-bottom: 70px;
  }

  .automated-home-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .automated-home-text {
    margin-bottom: 20px;
  }

  .automated-home-feature-single {
    padding: 19px;
  }

  .data-analysis-text {
    max-width: 100%;
    margin-bottom: 50px;
  }

  .data-analysis-img-wrap {
    text-align: center;
  }

  .data-analysis-wrap .row:nth-child(2) {
    margin-top: 70px;
  }

  .data-analysis-wrap .row:nth-child(2) .data-analysis-text {
    padding: 0;
  }

  .explore-home-inner {
    margin-bottom: 0;
  }

  .explore-home-single {
    padding: 0 20px;
    text-align: center;
    margin-bottom: 50px;
  }

  .global-home-icons-wrap {
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;
  }

  .secure-check-text-single {
    min-height: unset;
  }

  .secure-check-wrap {
    text-align: center;
  }

  .secure-check-text-single {
    display: block;
  }

  .secure-check-img {
    margin-bottom: 50px;
  }

  .secure-check-text-wrap .secure-check-text-single {
    margin-bottom: 50px;
  }

  .secure-check-text-single-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .secure-check-wrap.article-padd {
    padding: 80px 0 30px 0;
  }
}

@media all and (max-width: 768px) {
  .main-banner-wrap.article-padd {
    padding: 120px 0 200px 0;
  }

  .main-banner-links {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .main-banner-links .external-link {
    margin-bottom: 30px;
  }

  .main-banner-text p {
    margin-bottom: 20px;
  }

  .marketplace-sec-head-wrap {
    align-items: center;
    text-align: center;
  }

  .marketplace-wrap.article-padd {
    padding: 80px 0 60px 0;
  }

  .configurator-home-wrap.article-padd {
    padding: 80px 0 80px 0;
  }

  .features-home-wrap.article-padd {
    padding: 80px 0 80px 0;
  }

  .automated-home-head {
    align-items: center;
  }

  .automated-home-text {
    text-align: center;
  }

  .automated-home-wrap.article-padd {
    padding: 80px 0 80px 0;
  }

  .data-analysis-wrap.article-padd {
    padding: 80px 0 80px 0;
  }

  .global-home-wrap.article-padd {
    padding: 80px 0 80px 0;
  }

  footer.article-padd {
    padding: 80px 0 40px 0;
  }

  .footer-links {
    max-width: max-content;
    width: 100%;
    margin: 0 0 40px;
  }

  .footer-info-wrap {
    margin-bottom: 40px;
  }

  .footer-links-list ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  .footer-links-head {
    margin-bottom: 10px;
  }
}

@media all and (max-width: 475px) {
  .automated-home-wrap
    .automated-home-features-wrap:last-child
    .automated-home-feature-single {
    flex-direction: column;
    padding: 19px;
  }
  .needs-validation {
    padding: 0 20px;
  }
  .forget-pass p a,
  .checkbox-rember label,
  .create-acc p {
    font-size: 14px;
  }
  .heading_txt_login {
    padding: 25px 0 25px 0;
  }
  .needs-validation .form-outline {
    padding-bottom: 20px;
  }
  .heading_txt_login.pass h1 {
    font-size: 20px;
  }
  .heading_txt_login p {
    font-size: 14px;
    padding-top: 14px;
  }
  .user-email p {
    font-size: 14px;
  }
  span.plan-des {
    transform: translateY(-1px);
  }
  span.month {
    font-size: 14px;
    margin-right: 2px;
  }
  .upgrade-btn {
    margin-left: 23px;
  }
  .upgrade-btn a {
    font-size: 13px;
  }
  .upgrade-btn {
    margin: 0 23px;
  }

  .upgrade-btn.plan {
    display: block;
  }
  .upgrade-btn a {
    margin-top: 4px;
  }
  span.price {
    font-size: 16px;
    margin-right: 5px;
    transform: translateY(2px);
  }

  .automated-home-wrap
    .automated-home-features-wrap:last-child
    .automated-home-feature-single
    img:not(:last-child) {
    margin-bottom: 30px;
  }

  .main-banner-text h1 {
    font-size: 26px;
    line-height: 40px;
  }

  .marketplace-sec-head-text h2,
  .configurator-home-text h2,
  .features-home-head h2,
  .automated-home-text h2 {
    font-size: 26px;
  }

  .explore-home-single h3 {
    font-size: 20px;
  }

  .global-home-icons-wrap h2 {
    font-size: 26px;
  }

  .secure-check-head h2 {
    font-size: 26px;
  }
}

/*======================
@@@   Home css end   @@@ 
======================*/

/*======================================
@@@   Configurator Pages css start   @@@ 
======================================*/

/* header */

.nav-wraper.dashboard {
  position: relative;
  z-index: 99;
  padding: 0 18px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(8, 39, 89, 0.1);
}

.nav-wraper.dashboard .navbar.navbar-expand-lg {
  padding: 4px 0;
}

.nav-wraper.dashboard .navbar-brand {
  max-width: 90px;
  width: 100%;
  margin-right: 37px;
}
.dashboard-stats-wrap {
  padding: 0 4px;
}

.dashboard-stats-wrap .col-xl-3,
.dashboard-stats-wrap .col-lg-3 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.nav-wraper.dashboard .nav-btn {
  display: flex;
  align-items: center;
}
.dashboard-stats-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  margin-bottom: 16px;
}
.dashboard-stats-single-text {
  text-align: right;
}
.dashboard-stats-single-text h2 {
  font-family: "markpro-bold", sans-serif;
  font-size: 32px;
  margin-bottom: 3px;
}

.dashboard-stats-single-text p {
  font-family: "markpro-bold", sans-serif;
  font-size: 14px;
  color: #464a4d;
}

.cart-btn-wrap {
  position: relative;
  margin-right: 30px;
}

.cart-items {
  position: absolute;
  top: 5px;
  left: -6px;
  background: #099e43;
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 75%;
  color: #ffffff;
  padding: 0.25em 0.3em;
}

.profile-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: #099e43;
  font-family: "markpro-book", sans-serif;
  font-size: 12px;
  color: #ffffff;
}

.profile-btn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* main content header */

.main-content-head-wrap {
  display: flex;
  align-items: center;
  height: 45px;
  background: #f5f8ff;
  border-bottom: 1px solid #ccd9e8;
  padding: 0 18px;
}

.main-content-head-title h1 {
  font-size: 14px;
  color: #2f353a;
}

.main-content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-content-head-btns {
  display: flex;
  align-items: center;
}

.btn-blue-bg {
  display: block;
  font-family: "markpro-bold", sans-serif;
  font-size: 11px;
  color: #ffffff;
  background: #212f8e;
  border: 1px solid #212f8e;
  border-radius: 4px;
  padding: 5px 23px;
  margin: 0 8px 0 0;
}
.btn-blue-bg:hover {
  color: #212f8e;
  background: #ffffff;
}

.btn-green-bg {
  display: block;
  font-family: "markpro-bold", sans-serif;
  font-size: 11px;
  color: #ffffff;
  background: #099e43;
  border: 1px solid #099e43;
  border-radius: 4px;
  padding: 5px 23px;
  margin: 0 8px 0 0;
  text-decoration: none;
}

.btn-red-bg {
  display: block;
  font-family: "markpro-bold", sans-serif;
  font-size: 11px;
  color: #ffffff;
  background: #ff7f7f;
  border: 1px solid #ff7f7f;
  border-radius: 4px;
  padding: 5px 23px;
  margin: 0 8px 0 0;
  text-decoration: none;
}

.btn-green-bg:hover {
  color: #099e43;
  background: #ffffff;
}
.btn-red-bg:hover {
  color: #ff7f7f;
  background: #ffffff;
}

.bordered-btn {
  display: block;
  font-family: "markpro-bold", sans-serif;
  font-size: 11px;
  color: #212f8e;
  background: #ffffff;
  border: 1px solid #212f8e;
  border-radius: 4px;
  padding: 5px 23px;
}

.bordered-btn:hover {
  color: #ffffff;
  background: #212f8e;
}

/* main content-body */

.main-content-body-wrap {
  background: #f7f8fa;
  padding: 20px 18px 50px;
  /* height: calc(100vh - 93px); */
  overflow: auto;
}

.main-content-body-links-inner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px 4px 0px 0px;
}

.main-content-body-links-inner a {
  font-family: "caros-bold", sans-serif;
  font-size: 16px;
  color: #bfbfbf;
  width: 25%;
  text-align: center;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  padding: 12px 0;
}

.main-content-body-links-inner .active {
  color: #151a1e;
  border-bottom: 3px solid #099e43;
}

.main-content-body-links-inner .visited {
  color: #151a1e;
}

.main-content-body-output {
  margin-top: 30px;
}

.main-content-apps-wrap {
  display: flex;
  align-items: flex-start;
}

.main-content-apps {
  max-width: 422px;
  width: 100%;
  margin-right: 30px;
}

.main-content-body-output-head {
  margin-bottom: 16px;
}

.main-content-body-output-head p {
  font-family: "markpro-medium", sans-serif;
  font-size: 16px;
  color: #151a1e;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.main-content-app-single-img {
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  margin-right: 24px;
}

.main-content-app-single-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  border: 0.5px solid #d6dbee;
  border-radius: 4px;
}

.main-content-app-single-name p {
  font-family: "caros-bold", sans-serif;
  font-size: 16px;
  color: #151a1e;
  line-height: 24px;
  display: -webkit-box;
  max-width: 230px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.main-content-app-single-name a {
  font-family: "markpro-medium", sans-serif;
  font-size: 14px;
  color: #4361cd;
  text-decoration: underline;
  display: block;
  max-width: max-content;
  margin-top: 16px;
}

.main-content-app-single-name a:hover {
  color: #099e43;
}

.main-content-apps .accordion-button {
  min-height: 155px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px 4px 0 0;
  border: none;
}

.main-content-apps .accordion-button:focus {
  border-color: #d6dbee;
}

.main-content-apps .accordion-button::after {
  /* background-image: url(../images/apps-arrow.svg); */
  margin-right: -10px;
  transform: rotate(0deg);
}

.main-content-apps .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-radius: 4px;
}

.main-content-apps .accordion-button:not(.collapsed)::after {
  /* background-image: url(../images/apps-arrow.svg); */
  transform: rotate(90deg);
}

.main-content-apps .accordion-body {
  background: #ffffff;
  padding: 20px;
  margin-top: 0;
  border-left: 2px solid #099e43;
}

.main-content-apps .accordion-collapse {
  border: none;
}

.accordion .accordion-item:not(:last-child) {
  margin-bottom: 16px;
}

.main-content-apps-view {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
  min-height: 668px;
}

.main-content-apps-view-inner {
  text-align: center;
}

.main-content-apps-view-inner-text {
  margin-top: 24px;
}

.main-content-apps-view-inner-text p {
  font-family: "markpro-bold", sans-serif;
  font-size: 16px;
  color: #999999;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.builds-btn-mobile {
  display: none;
}

.builds-btn-mobile:hover {
  color: #212f8e;
}

.main-content-apps.component-pg .accordion-button {
  min-height: 60px;
}

.main-content-apps.component-pg .main-content-app-single-name p {
  max-width: 100%;
  -webkit-line-clamp: 1;
}

.selected-option-content {
  display: flex;
  align-items: center;
}

.selected-option-content-img {
  min-width: 90px;
  max-width: 90px;
  height: 90px;
  margin-right: 24px;
}

.selected-option-content-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  border: 0.5px solid #d6dbee;
  border-radius: 4px;
}

.selected-option-content-name p {
  font-family: "caros-bold", sans-serif;
  font-size: 14px;
  color: #151a1e;
  line-height: 20px;
  display: -webkit-box;
  max-width: 230px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.selected-option-content-name a {
  font-family: "markpro-medium", sans-serif;
  font-size: 13px;
  color: #4361cd;
  text-decoration: underline;
  display: block;
  max-width: max-content;
  margin-top: 16px;
}

.selected-option-content-name a:hover {
  color: #099e43;
}

/* save build modal starts */
.save-build-modal .modal {
  background: rgba(4, 11, 36, 0.35);
}

.save-build-modal .modal-header {
  justify-content: center;
  border-bottom: 1px solid #d6dbee;
}

.save-build-modal .modal-header .btn-close {
  position: absolute;
  top: 22px;
  right: 19px;
  opacity: 1;
}

.save-build-modal .modal-header .btn-close:focus {
  box-shadow: none;
}

.save-build-modal .modal-body {
  margin-top: 16px;
}

.save-build-modal .modal-body .build-name-field {
  font-family: "markpro-bold", sans-serif;
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d6dbee;
  padding: 10px 16px;
  line-height: initial;
}

.save-build-modal .modal-body .build-name-field::placeholder {
  color: #bfbfbf;
}

.save-build-modal .modal-body .build-name-field:focus-visible {
  border: 1px solid #099e43;
  outline: none;
}

.save-build-modal .modal-footer {
  justify-content: center;
  border: none;
}

.save-build-modal .modal-footer button {
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  border: none;
}
/* save build modal ends */

/* show builds modal starts */

.show-build-modal .modal {
  background: rgba(4, 11, 36, 0.35);
}

.show-build-modal .modal-header {
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid #d6dbee;
}

.show-build-modal .modal-header .btn-close {
  position: absolute;
  right: 25px;
  opacity: 1;
}

.show-build-modal .modal-header .btn-close:focus {
  box-shadow: none;
}

.show-build-modal .modal.modal-left .modal-dialog {
  max-width: 330px;
  width: 100%;
}

.show-build-modal .modal.modal-left .modal-body {
  padding: 30px 25px;
  overflow-x: hidden;
}

.cart-drawer {
  position: relative !important;
}

.cart-drawer .modal-body-inner .modal-build-single:not(:last-child) {
  margin-bottom: 0px;
}

.modal-build-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-build-single-text {
  display: flex;
  align-items: center;
}

.modal-build-single-name {
  margin-left: 18px;
}

.modal-build-single-name p {
  font-family: "markpro-medium", sans-serif;
  font-size: 16px;
  color: #151a1e;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-build-single-btns {
  display: flex;
  align-items: center;
}

.modal-build-single-btns a:not(:last-child) {
  margin-right: 25px;
}

/* show builds modal ends */

/* CONFIGURATOR PAGE MEDIA QUERIES */

@media all and (max-width: 1400px) {
  .main-content-app-single-name p {
    max-width: 170px;
  }
}

@media all and (max-width: 1200px) {
  .main-content-app-single-name p {
    max-width: 120px;
    -webkit-line-clamp: 1;
  }
}

@media all and (max-width: 1160px) {
  .card-info p {
    margin-top: -5px;
  }
  .card-info span {
    font-size: 20px;
  }
}

@media all and (max-width: 992px) {
  .navbar-toggler {
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
  }

  .nav-wraper.dashboard .navbar-collapse {
    top: 50px;
  }

  .nav-wraper.dashboard .navbar-light .navbar-toggler {
    margin: 0 0 0 15px;
  }

  .builds-btn-desktop {
    display: none;
  }

  .builds-btn-mobile {
    font-family: "markpro-medium", sans-serif;
    font-size: 14px;
    color: #099e43;
    display: block;
    margin: 0 0 0 auto;
    padding: 4.5px 23px;
    border: 1px solid #099e43;
    border-radius: 4px;
  }

  .main-content-body-links-inner a {
    font-size: 14px;
  }

  .main-content-apps-view {
    min-height: auto;
    padding: 60px 20px;
  }

  .nav-wraper.dashboard .nav-btn {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-btn-wrap,
  .profile-btn-wrap {
    padding: 8px 0;
    margin-left: auto;
    margin-right: 20px;
  }

  .selected-option-content-name p {
    max-width: 340px;
  }

  .main-content-app-single-name p {
    max-width: 500px;
    -webkit-line-clamp: 2;
  }
}

@media all and (max-width: 768px) {
  .main-content-body-links-inner {
    flex-direction: column;
  }
  .needs-validation {
    margin: 80px auto;
  }
  .dashboard-stats-single-text h2 {
    font-size: 26px;
  }

  .dashboard-stats-single-text p {
    font-size: 13px;
  }

  .main-content-body-links-inner a {
    width: 100%;
  }

  .main-content-app-single-name p {
    max-width: 330px;
    -webkit-line-clamp: 2;
  }

  .main-content-apps-wrap {
    flex-direction: column;
  }

  .main-content-apps {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

@media all and (max-width: 575px) {
  .main-content-head-wrap {
    height: auto;
    padding: 30px 0;
  }

  .profile-needs-validation {
    margin-left: 0;
  }
  .logedin-nav .navbar-collapse {
    top: 48px !important;
    left: 0;
  }
  .logedin-nav {
    padding: 8px 18px 8px 13px !important;
    box-shadow: 0px 2px 10px rgb(8 39 89 / 10%) !important;
  }
  .dashboard-head-wrap {
    top: 42px !important;
  }
  .profile-select-menu-mb-top .MuiPopover-paper {
    top: 42px !important;
  }
  .navbar-brand {
    order: unset;
    right: 0;
    left: 0;

    width: 90px !important;
    /* max-width: 90px !important; */
  }
  .profile-brand {
    position: absolute;
    margin: auto !important;
    order: unset;
    right: 0;
    left: 0;

    width: 90px !important;
    /* max-width: 90px !important; */
  }
  .navbar-toggler-icon {
    width: 1.3rem !important;
    height: 1.2rem !important;
  }
  .navbar-toggler {
    margin-left: auto;
    /* margin-right: 18px; */
    padding: 4px 10px !important;
  }
  .navbar-toggler {
    outline: none !important;
    box-shadow: none !important;
  }
  /* navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem;
} */
  .config-login-toggler {
    margin: 0 !important;
  }
  /* .navbar-toggler {
    width: 48px;
    height: 40px !important;
  }
  .navbar-toggler-icon {
    width: 24px !important;
  } */
  .navbar-toggler-icon {
    height: 1.3rem !important;
  }
  .plan-option {
    padding-left: 23px;
  }
  .subscription-plan p {
    padding: 23px;
  }
  .subscription-txt h1 {
    padding-left: 23px;
  }
  .order-analytics-single-title {
    margin-bottom: 20px;
  }
  .order-analytics-single-head {
    flex-direction: column;
  }
  .dashboard-head-wrap {
    padding: 0 29px;
    top: 46px;
  }
  .dashboard-head-wrap-build-page {
    padding: 0 29px;
  }
  .dashboard-content-wrap .main-content-inner {
    padding: 20px;
  }

  .main-content-head {
    flex-direction: column;
  }

  .main-content-head-title {
    margin-bottom: 15px;
  }

  .main-content-apps .accordion-button {
    flex-direction: column;
    align-items: flex-start;
  }

  .main-content-app-single-img {
    margin: 0 0 20px 0;
  }

  .main-content-apps .accordion-button::after {
    position: absolute;
    right: 19px;
    top: 50%;
    margin-top: -9px;
  }

  .show-build-modal .modal.modal-left .modal-dialog {
    max-width: 300px;
  }

  .show-build-modal .modal.modal-left .modal-body {
    padding: 20px;
  }

  .modal-build-single-name p {
    max-width: 160px;
  }

  .modal-build-single-name {
    margin-left: 10px;
  }

  .main-content-app-single-name p {
    max-width: 100%;
    display: block;
  }

  .main-content-app-single-name a {
    margin: 16px 0 0;
  }

  .selected-option-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .selected-option-content-img {
    margin: 0 0 20px;
  }

  .selected-option-content-name a {
    margin: 16px 0 0;
  }

  .builds-btn-mobile {
    font-size: 12px;
    padding: 7px 15px 5px;
  }
}

/*====================================
@@@   Configurator Pages css end   @@@ 
====================================*/

/*================================
@@@   Pricing Page css start   @@@ 
================================*/

.nav-wraper.inner {
  background: #f7f8fa;
}

.inner-banner-outer-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url(../images/inner-banner-img.png) no-repeat; */
  background-size: cover;
  height: 400px;
}

.inner-banner-text {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.inner-banner-text h1 {
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 22px;
}

.inner-banner-text p {
  font-family: "markpro-reg", sans-serif;
  font-size: 24px;
  line-height: 35px;
}

/* pricing plans */

.pricing-head-wrap {
  text-align: center;
  margin-bottom: 60px;
}

.pricing-wrap-inner {
  display: flex;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.pricing-wrap-single {
  width: 300px;
  border-radius: 4px;
  background: #ffffff;
  filter: drop-shadow(0px 26px 32px rgba(179, 188, 211, 0.273492));
}

.pricing-wrap-inner .pricing-wrap-single:not(:last-child) {
  margin-right: 50px;
}

.pricing-wrap-single-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  min-height: 122px;
  background: #13214a;
  border-radius: 4px 4px 0 0;
}

.pricing-wrap-single-head p {
  font-family: "caros-med", sans-serif;
  font-size: 20px;
  color: #ffffff;
}

.pricing-wrap-single-body {
  padding: 30px 50px;
}

.pricing-wrap-single-body-text p {
  font-family: "markpro-heavy", sans-serif;
  font-size: 16px;
  color: #878787;
}

.pricing-wrap-single-body-text p span {
  font-size: 24px;
  color: #151a1e;
}

.pricing-wrap-single-body-text {
  text-align: center;
}

.pricing-wrap-single-body-list {
  margin: 15px 0 30px;
}

.pricing-wrap-single-body-list ul li {
  font-family: "markpro-medium", sans-serif;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #151a1e;
}

.pricing-wrap-single-body-list ul li:not(:last-child) {
  margin-bottom: 15px;
}

.pricing-wrap-single-body-list ul li img {
  margin-right: 10px;
  width: 16px;
}

.pricing-btn .green-btn {
  font-family: "markpro-bold", sans-serif;
  font-size: 16px;
  max-width: 100%;
  padding: 14px 30px;
  text-transform: uppercase;
  text-align: center;
}

/* cta */

.cta-wrap-outer {
  background: #13214a;
  min-height: 305px;
  padding: 70px 30px;
}

.cta-wrap-inner {
  text-align: center;
}

.cta-wrap-inner h3 {
  font-family: "caros-bold", sans-serif;
  font-size: 32px;
  line-height: 55px;
  color: #ffffff;
  margin-bottom: 10px;
}

.cta-wrap-inner p {
  font-family: "markpro-reg", sans-serif;
  font-size: 20px;
  line-height: 35px;
  color: #ffffff;
}

.cta-btn {
  margin-top: 30px;
}

.cta-btn .green-btn {
  margin: 0 auto;
  padding: 8px 30px;
}

.cta-btn .green-btn:hover {
  background: #ffffff;
  color: #13214a;
}

/* PRICING PAGE MEDIA QUERIES */

@media all and (max-width: 1200px) {
  .pricing-wrap-single-head p {
    font-size: 18px;
  }

  .pricing-wrap-single-body {
    padding: 30px;
  }
}

@media all and (max-width: 992px) {
  .pricing-wrap-inner {
    flex-direction: column;
  }

  .pricing-wrap-inner .pricing-wrap-single:not(:last-child) {
    margin: 0 auto 50px;
  }

  .pricing-wrap-single {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  .cta-wrap-outer {
    padding: 70px 20px;
  }

  .cta-wrap-inner h3 {
    font-size: 28px;
    line-height: 47px;
  }
}

@media all and (max-width: 768px) {
  .pricing-wrap-outer.article-padd {
    padding: 80px 0 80px 0;
  }

  .pricing-head-wrap {
    margin-bottom: 30px;
  }
}

@media all and (max-width: 575px) {
  .inner-banner-text h1 {
    font-size: 34px;
    line-height: 47px;
  }
  .navbar > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .dashboard-head-wrap-build-page {
    flex-direction: column;
    padding: 30px;
    top: 47px;
    z-index: 800;
  }
  .main-content-head-btns {
    margin-top: 15px;
  }
  .config-screen {
    padding-top: 90px;
  }
  .inner-banner-text p {
    font-size: 22px;
    line-height: 32px;
  }

  .inner-banner-text h1 br,
  .inner-banner-text p br {
    display: none;
  }
}

/*===============================
@@@   Pricing Page css end   @@@ 
===============================*/

/*================================
@@@   Contact Page css start   @@@ 
================================*/

.contact-wrap-outer.article-padd {
  padding: 100px 0 75px;
}

.contact-head {
  text-align: center;
  margin-bottom: 60px;
}
.contact-form {
  background: #ffffff;
}

.contact-form-wrap {
  max-width: 822px;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 6px 32px rgb(179 188 211 / 27%);
  border-radius: 4px;
  padding: 40px;
}

.contact-form-wrap .col-12 {
  margin: 0;
}
.botics-footer {
  background: #ffffff;
}

.contact-form-wrap input.form-control {
  height: 66px;
}

.contact-form-wrap .form-control {
  font-family: "markpro-medium";
  font-size: 18px;
  color: #151a1e;
  border: 1px solid #eeebeb;
  margin-bottom: 30px;
  padding: 0.375rem 21px;
}

.contact-form-wrap .form-control:focus {
  box-shadow: none;
  border: 1px solid #099e43;
}

.contact-form-wrap .form-control::placeholder {
  color: #878787;
}

.contact-form-wrap textarea {
  resize: none;
  padding: 22px 21px !important;
}

.form-btn-wrap .green-btn {
  font-family: "markpro-medium", sans-serif;
  font-size: 18px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  padding: 14px 30px;
  outline: none;
  border: none;
  box-shadow: 0px 26px 32px rgba(179, 188, 211, 0.27);
  transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
}

/* CONTACT PAGE MEDIA QUERIES */

@media all and (max-width: 768px) {
  .contact-wrap-outer.article-padd {
    padding: 80px 0 80px 0;
  }

  .contact-head {
    margin-bottom: 30px;
  }
}

@media all and (max-width: 575px) {
  .contact-form-wrap {
    padding: 30px 15px;
  }

  .contact-form-wrap input.form-control {
    height: 50px;
  }

  .contact-form-wrap .form-control {
    padding: 0.375rem 15px;
  }

  .contact-form-wrap textarea {
    padding: 12px 15px !important;
  }

  .inner-banner-outer-wrap {
    height: 350px;
  }

  .contact-form-wrap .form-control {
    font-family: "markpro-reg";
    font-size: 16px;
    margin-bottom: 20px;
  }

  .form-btn-wrap .green-btn {
    font-family: "markpro-reg", sans-serif;
    font-size: 16px;
    padding: 10px 30px;
  }

  .contact-head h2 {
    padding: 0 20px;
  }
}

/*==============================
@@@   Contact Page css end   @@@ 
==============================*/

/*================================
@@@   Company Page css start   @@@ 
================================*/

.company-outer-wrap {
  padding: 124px 0 100px;
  background: #ffffff;
}

.company-text-wrap {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 600px;
  width: 100%;
}

.company-text h2 {
  margin-bottom: 24px;
}

.company-text p {
  line-height: 25px;
}

.company-text p:not(:last-child) {
  margin-bottom: 24px;
}

.company-img-wrap {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
  border-radius: 4px;
  width: 100%;
  padding: 4px;
  max-width: 615px;
  margin: auto 0 auto auto;
}

.company-img-wrap img {
  border-radius: 4px;
  width: 100%;
}

/* recent articles */

.recent-articles-outer-wrap {
  padding-bottom: 110px;
  background: #ffffff;
}

.config-stepper .MuiStep-horizontal {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.recent-article-single {
  box-shadow: 0px 6px 32px rgb(179 188 211 / 27%) !important;
  background: #ffffff;
}
.recent-article-inner-wrap {
  margin-top: 60px;
}
.recent-article-inner-wrap .carousel-root .carousel .slider-wrapper {
  background: white !important;
}
.charge-payment-modal .MuiDialog-container .MuiPaper-root {
  height: auto !important;
  overflow: scroll !important;
}

.shipping-address h3 {
  font-size: 18px;
}
.recent-article-inner-wrap.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.recent-article-inner-wrap.owl-carousel .owl-item {
  opacity: 0;
  transition: opacity 200ms;
}

.recent-article-inner-wrap.owl-carousel .owl-item.active {
  opacity: 1;
}

.recent-articles-head {
  max-width: 800px;
  width: 100%;
  margin: 0 auto 30px;
  text-align: center;
}

.recent-articles-head h2 {
  margin-bottom: 24px;
}

.recent-articles-head p {
  font-size: 18px;
  line-height: 28px;
}

.recent-article-single {
  max-width: 100%;
  width: 100%;
  min-height: 294px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 32px rgba(179, 188, 211, 0.273492);
  border-radius: 4px;
  margin: 0;
}

.recent-article-single-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.recent-article-single-name h3 {
  font-family: "markpro-medium", sans-serif;
  font-size: 20px;
  margin-bottom: 5px;
}

.recent-article-single-name p {
  font-size: 16px;
  line-height: 28px;
  color: #878787;
}

.recent-article-single-body p {
  font-size: 18px;
  line-height: 28px;
}

.recent-article-single-img {
  min-width: 80px;
  max-width: 80px;
  height: 80px;
}

.recent-article-single-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.recent-articles-outer-wrap .owl-carousel .owl-dots.disabled,
.recent-articles-outer-wrap .owl-carousel .owl-nav.disabled {
  display: block;
}

.recent-articles-outer-wrap .owl-nav {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.recent-articles-outer-wrap .owl-nav button .prev-btn,
.recent-articles-outer-wrap .owl-nav button .next-btn {
  border: 2px solid rgba(21, 26, 30, 0.5);
  box-sizing: border-box;
  border-radius: 50px;
  padding: 7px 22px;
  margin: 0 7.5px;
  /* transition: all ease-in-out .3s;
  -moz-transition: all ease-in-out .3s;
  -webkit-transition: all ease-in-out .3s;
  -o-transition: all ease-in-out .3s; */
}

.recent-articles-outer-wrap .owl-nav button .next-btn:hover {
  /* background: url(../images/long-right-arrow-black.svg) no-repeat; */
  background-position: center center;
  border: 2px solid #151a1e;
}

.recent-articles-outer-wrap .owl-nav button .prev-btn:hover {
  /* background: url(../images/long-left-arrow-black.svg) no-repeat; */
  background-position: center center;
  border: 2px solid #151a1e;
}

/* our culture */

.our-culture-wrap {
  background: #f7f8fa;
  padding: 80px 12px;
}

.our-culture-single-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 359px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.our-culture-single-wrap.left {
  margin-right: 8px;
}

.our-culture-single-wrap.right {
  margin-left: 8px;
}

.our-culture-single img {
  margin-bottom: 32px;
}

.our-culture-single h3 {
  font-size: 32px;
  margin-bottom: 16px;
}

.our-culture-single p {
  font-size: 18px;
  line-height: 28px;
  max-width: 493px;
  width: 100%;
  margin: 0 auto;
}

/* careers */

.careers-outer-wrap {
  padding: 100px 0;
  background: #ffffff;
}

.careers-img-wrap {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
  border-radius: 4px;
  width: 100%;
  padding: 4px;
  max-width: 615px;
  margin: auto auto auto 0;
}

.careers-img-wrap img {
  border-radius: 4px;
  width: 100%;
}

.careers-text-wrap {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 600px;
  width: 100%;
  margin: auto 0 auto auto;
  padding: 0 0 0 14px;
}

.careers-text h2 {
  margin-bottom: 24px;
}

.careers-text p {
  line-height: 25px;
}

.careers-text p:not(:last-child) {
  margin-bottom: 24px;
}

/* COMPANY PAGE MEDIA QUERIES */

@media all and (max-width: 1400px) {
  .our-culture-single-wrap {
    min-height: 386px;
  }

  .our-culture-single h3 {
    font-size: 28px;
  }

  .recent-article-single {
    min-height: 306px;
  }
}

@media all and (max-width: 1200px) {
  .our-culture-single-wrap {
    min-height: 409px;
  }
}

@media all and (max-width: 992px) {
  .company-text-wrap {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .company-img-wrap {
    max-width: 100%;
    margin: 40px auto 0;
  }

  .our-culture-single-wrap.left {
    margin-right: 0;
    margin-bottom: 50px;
  }

  .our-culture-single-wrap.right {
    margin-left: 0;
  }

  .careers-text-wrap {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  .careers-btn .green-btn {
    margin: 0 auto;
  }

  .careers-img-wrap {
    max-width: 100%;
    margin: 40px auto 0;
  }

  .our-culture-single-wrap {
    min-height: unset;
  }

  .recent-article-single {
    min-height: 230px;
  }
}

@media all and (max-width: 768px) {
  .company-outer-wrap {
    padding: 80px 0 80px;
  }

  .careers-outer-wrap {
    padding: 80px 0;
  }

  .navbar.navbar-expand-lg {
    padding: 20px 0;
  }

  .navbar-collapse {
    top: 76px;
  }

  .our-culture-single h3 {
    font-size: 24px;
  }

  .recent-articles-outer-wrap {
    padding-bottom: 70px;
  }
}

@media all and (max-width: 475px) {
  .company-outer-wrap {
    padding: 50px 0 50px;
  }
  .plan-option .form-check.form-check-inline input {
    margin: 5px 6px 0 0;
  }
  .plan-option .form-check.form-check-inline {
    padding: 6px 15px 6px 16px;
    flex-basis: 50%;
  }

  .our-culture-single-wrap {
    padding: 25px;
  }

  .our-culture-single img {
    margin-bottom: 20px;
  }

  .careers-outer-wrap {
    padding: 50px 0;
  }

  .recent-articles-outer-wrap {
    padding-bottom: 50px;
  }

  .recent-article-single-name p {
    line-height: 22px;
  }
}

/*==============================
@@@   Company Page css end   @@@ 
==============================*/

/*===================================
     @@@ Marketplace css starts @@@ 
=====================================*/

/* Resets */

.bg-inner {
  background-color: #f7f8fa;
}

/* Application links */

.accordion-body ul li a {
  color: #151a1e;
  font-size: 14px;
  font-family: "markpro-book";
}

.accordion-heads {
  font-family: "caros-med";
  font-size: 22px;
}

.accordion-body ul li {
  margin-bottom: 10px;
}

.heading_txt h1 {
  font-size: 32px;
  font-family: "caros-bold";
}

.products .accordion-body ul li a:hover {
  color: #099e43;
}

.products .accordion-body ul li:last-child {
  margin-bottom: 0;
}

.products .accordion-body {
  padding: 24px 0;
  border-bottom: 1px solid #e1e1e1;
}

.products .accordion-button:not(.collapsed) {
  color: #151a1e;
  background-color: transparent;
  font-size: 22px;
  font-family: "caros-med";
}

.products .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.products .accordion-button {
  font-size: 22px;
  color: #151a1e;
  border-bottom: 1px solid #e1e1e1;
}

.products .accordion-collapse {
  border: none;
}

.products .accordion-item:first-of-type .accordion-button {
  border-bottom: 1px solid #e1e1e1;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding-left: 0;
}

.products .accordion-item .accordion-button {
  border-bottom: 1px solid #e1e1e1;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding-left: 0;
}

.products .accordion-body .form-check-label {
  margin: 0 10px 10px 10px;
}

.form-check-input:checked {
  background-color: #089e42 !important;
  border-color: #089e42 !important;
  border: 1px solid #089e42 !important;
}

.product-img img {
  /* width: 144px; */
  height: 200px;
}

.form-check-input:focus {
  border: 1px solid #242424;
  box-shadow: none;
}

.form-check-input {
  border: 1px solid #242424;
  border-radius: 0;
}

.current-page {
  margin-bottom: 25px;
}

div#accordionExample {
  margin-top: 26px;
  padding: 0;
}

.products .accordion-button:not(.collapsed)::after {
  /* background-image: url(../images/apps-arrow-black.svg) !important; */
  transform: rotate(180deg);
}

.products .accordion-button::after {
  /* background-image: url(../images/apps-arrow-black.svg) !important; */
}

.add-to-cart a {
  text-decoration: none;
}
.add-to-cart a:hover {
  color: #ffffff;
  background-color: #212f8e;
}
.add-to-cart button:hover {
  color: #ffffff;
  background-color: #212f8e;
}
.customize a {
  text-decoration: none;
}
.get-help a {
  text-decoration: none;
}

.get-help a:hover {
  border: 1px solid #212f8e;
  background-color: transparent;
  color: #212f8e;
}

.customize a:hover {
  background-color: #212f8e;
  color: #fff;
}

.customize {
  width: 100%;
  /* max-width: 166px; */
  text-align: center;
  color: #222f8e;
}

/* bar */

.search {
  position: relative;
  width: 100%;
}

.search input {
  text-indent: 35px;
  border: 1px solid #d6d4d4;
  max-width: 303px;
  padding: 3.5px 0;
  border-radius: 4px;
}

.search input:focus {
  box-shadow: none;
  border: 1px solid #089e42;
}

.search .fa-search {
  position: absolute;
  top: 24%;
  left: 15px;
  color: #b2b8c7;
}

.search input::placeholder {
  color: #b2b8c7;
  font-size: 14px;
  font-family: "markpro-medium";
}

.filter-details .form-select {
  max-width: 220px;
  font-size: 14px;
  font-family: "markpro-book";
  padding: 6px 10px;
  border-radius: 4px;
}

.filter-details {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: flex-end;
}

.search-area {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.filter-details label {
  margin-right: 10px;
  font-size: 14px;
  font-family: "markpro-book";
}

.form-select:focus {
  border: 1px solid #d6d4d4;
  outline: 0;
  box-shadow: none;
}

.form-select {
  border: 1px solid #d6d4d4;
  border-radius: 4px;
}

.items {
  background-color: #fff;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08));
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 12px;
  padding-bottom: 0px;
}

.items h3 {
  font-size: 13px;
  font-family: "markpro-book";
  color: #151a1e;
  padding: 10px 0 5px 0;
}

.product-img {
  padding: 15px;
  text-align: center;
  height: 200px;
  margin-bottom: 11px;
}

.product-details {
  padding: 0 10px 1px;
}

.product-thumb-single {
  padding: 10px;
  background: white;
  margin-left: 10px;
  cursor: pointer;
}

.product-details p {
  font-family: "markpro-book";
  color: #8b8e90;
  font-size: 12px;
}

hr:not([size]) {
  margin: 0;
  color: #888888;
  height: 1.1px;
}
.product-pagination {
  /* display: none; */
}

.product-pagination nav {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  /* max-width: 50%; */
  padding: 50px 0 0;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: none;
}

/* Pagination */

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 4px;
  color: #919191;
}

.pagination {
  justify-content: center;
}

.page-link {
  padding: 2px 16.2px;
  text-decoration: none;
  color: #8a8c8f !important;
}

.page-link {
  color: #242424;
  border: 1px solid #e1e1e1;
  margin: 0 10px;
}

i.fa.fa-angle-left {
  color: #919191;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
  border-radius: 4px;
}

li.page-item.total-pages a {
  background-color: transparent;
  border: none;
}

.page-item span.total-pages {
  padding: 0 20px 0 10px;
}

li.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

li.page-item.page a {
  padding: 2px 23.2px;
}

.active-btn a {
  border: 1px solid #242424;
}

.page-link:hover {
  color: #242424;
  background-color: #fff;
  border-color: #242424;
}

.page-link:focus {
  color: #242424;
  background-color: #fff;
  box-shadow: none;
}

/* footer */
footer.article-padd.inner {
  background-color: #fff;
  margin-top: 100px;
}

/* slider */

.slider-container {
  margin: 30px 0 20px;
  max-width: 100% !important;
}

.theme-green .back-bar .pointer {
  width: 14px;
  height: 14px;
  top: -5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #099e43 !important;
  background-color: #099e43 !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
}

.slider-container .scale ins {
  left: -4px;
  top: 12px;
}

.theme-green .back-bar .selected-bar {
  border-radius: 2px;
  background-color: #efefef !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
}

.theme-green .back-bar .selected-bar {
  background-color: #099e43 !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
  background-image: none !important;
}

.theme-green .scale span {
  border-left: 1px solid #d0d0d0 !important;
}

.slider-container .scale span {
  height: 8px !important;
}

.slider-container .scale ins {
  top: 12px !important;
  left: -5px !important;
}

/* End */

/* popup */

.pop-model {
  height: 100%;
  background-color: rgb(4 11 36 / 65%);

  padding: 0 !important;
}

.pop-model .modal-open {
  padding: 0 !important;
}

.pop-model .modal-body,
.pop-model .modal-header {
  padding: 0;
}

.pop-model .modal-content {
  /* width: 100%; */
  background-color: #f7f8fa;
  border-radius: 4px;
  padding: 47px 10px 67px 30px;
}

.pop-model .modal-dialog {
  max-width: 1280px !important;
}

.pop-model .modal.fade.show {
  padding-right: 0 !important;
}

.pop-model .modal-header {
  border-bottom: 0;
}

/* slider */

.product-detail-images img {
  /* width: 80% !important; */
  margin: 0 auto;
}

.product-detail-images {
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f8fa;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: 464px;
}

.product-thumb-images .owl-item:last-child {
  margin-right: 0 !important;
}

.product-detail-images .owl-nav {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 50%;
}

.product-detail-images .owl-nav .owl-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  border-radius: 50%;
  box-shadow: 0px 26px 32px rgba(179, 188, 211, 0.2735);
}

.product-detail-images .owl-nav .owl-next {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
  box-shadow: 0px 26px 32px rgba(179, 188, 211, 0.2735);
}

.product-thumb-images .owl-stage {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
.product-thumb-images {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  background: #f7f8fa;
}

.product-thumb-images .owl-item {
  width: 100% !important;
  background: #fff;
  border-radius: 2px;
  padding: 6px 12px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
  border-radius: 4px;
}

.product-thumb-images .owl-item.synced {
  border: 1px solid #089e42;
  box-sizing: border-box;
  border-radius: 2px;
}

.product-img-single {
  /* padding: 0 60px 0;
  max-width: 552px; */
  background: white;
  width: 100%;
}

.product-thumb-images {
  width: 100%;
  max-width: 100%;
}

.product-detail h1 {
  font-family: "markpro-book";
  font-size: 32px;
  margin-bottom: 15px;
}

.product-detail p {
  font-size: 14px;
  font-family: "markpro-book";
  color: #151a1e;
}

.product-detail p a {
  color: #8b8e90;
  text-decoration: underline;
}

.product-detail p a:hover {
  color: #089e42;
}

.product-detail h3 {
  margin: 30px 0 20px;
  font-family: "markpro-medium";
}

/* count */

button.qty-count.qty-count--add,
button.qty-count.qty-count--minus {
  padding: 10.5px 20px;
  background-color: #fff;
  border: 0.5px solid #c0c0c0;
}
button.qty-count.qty-count--minus {
  padding: 10.5px 20px;
  background-color: #fff;
  border: 0.5px solid #c0c0c0;
}

button.qty-count.qty-count--add {
  border-radius: 0 4px 4px 0;
}

button.qty-count.qty-count--minus {
  border-radius: 4px 0 0 4px;
}

.qty-input {
  display: flex;
  margin-right: 19px;
}
.qty-input .cart-des {
  display: flex;
  margin-right: 0;
  align-items: center;
  border: 0.5px solid #afafaf;
  border-radius: 2px;
  margin-left: 20px;
}
.qty-input.cart-des input {
  width: 28px;
  height: 28px;
  text-align: center;
  border: 0.5px solid #7a7a7a !important;
  outline: 0;
  border: 0;
  align-items: center;
}
.cart-des button.qty-count.qty-count--minus {
  border-radius: 0;
  width: 22px;
  height: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0;
  border-left: 0;
}
.cart-drawer .model-left-inner {
  border: 0.5px solid #c7c6c6;
  border-radius: 4px;
  /* padding: 14px 15px; */
  flex-basis: 30%;
  position: relative;
}
.cart-drawer .model-left-product h4 {
  font-size: 14px;
  font-family: "markpro-book", sans-serif;
  max-width: 160px;
  padding-bottom: 10px;
}

.cart-drawer .model-left-product p {
  color: #8b8e90;
  font-size: 12px;
  font-family: "markpro-book", sans-serif;
}

.modal-build-single-name {
  flex-basis: 60%;
}
.modal-build-single-name p {
  font-family: "markpro-medium", sans-serif;
  font-size: 16px;
  color: #151a1e;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-details-cart {
  padding: 25px 17px;
}

.cart-drawer .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0px;
  outline: 0;
  flex-basis: 60%;
}

.cart-drawer .MuiDrawer-paperAnchorRight {
  max-width: 391px;
  width: 100%;
}
.cart-img {
  position: relative;
  top: -6px;
}
.cart-close-btn {
  position: relative;
  top: -2px;
  color: #000000;
  width: 2em;
  opacity: 1 !important;
}

.cart-drawer .modal-header {
  position: fixed;
  padding: 20px !important;
  padding-top: 10px;
  z-index: 9;
  max-width: 391px;
  width: 100%;
  background: #ffffff !important;
}
.cart-drawer .modal-dialog {
  margin: 0px !important;
}
.cart-drawer .modal-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0;
  margin-top: 75px;
}
.qty-input {
  margin-right: 0px;
}
.cart-des {
  margin-right: 0px;
}
.quote-btn a {
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: center;
  bottom: 0;
  background-color: #099e43;
  color: #fff !important;
  padding: 20px 0;
  font-family: "markpro-medium", sans-serif;
  font-size: 16px;
  text-decoration: none;
  max-width: 391px;
  width: 100%;
}
.quote-btn a:hover {
  background-color: #212f8e;
  color: #fff;
}
.cart-des button.qty-count.qty-count--add {
  border-radius: 0;
  width: 22px;
  height: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid #7a7a7a;
  border-left: 0;
}

button.qty-count.qty-count--add {
  padding: 0 10px;
  background-color: #fff;
}

.cart-des button.qty-count.qty-count--add {
  padding: 0 10px;
  background-color: #fff;
  border: 0.5px solid #7a7a7a;
  border-left: 0;
}
button.qty-count.qty-count--minus {
  padding: 0 10px;
  background-color: #fff;
  /* border: 0.5px solid #7a7a7a; */
  /* border-left: 0; */
}
.cart-des button.qty-count.qty-count--add,
button.qty-count.qty-count--add {
  /* border-left: 0 !important; */
}
.cart-des button.qty-count.qty-count--add,
button.qty-count.qty-count--add {
  padding: 0 10px;
  background-color: #fff;
  /* border: 0.5px solid #7a7a7a; */
}

.quantity-outer-wrap input.product-qty {
  background-color: #fff;
  padding: 10.3px 0 10.3px 8px;
  border: 0.5px solid #c7c7c7;
  text-align: center;
  border-left: 0;
  border-right: 0;
  outline: 0;
  width: 100%;
  max-width: 66px;
}

.quantity-outer-wrap label.form-label {
  font-size: 18px;
  font-family: "markpro-medium";
  margin: 30px 0 15px;
}

.qty-input input::-webkit-outer-spin-button,
.qty-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.qty-input input[type="number"] {
  -moz-appearance: textfield;
}

.cart-btn {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.links-btn {
  text-align: center;
  width: 100%;
  max-width: 396px;
}

.add-to-cart a {
  background-color: #099e43;
  color: #fff;
  padding: 12px 0;
  border-radius: 4px;
  display: block;
  font-size: 15px;
  font-family: "markpro-medium";
}

.add-to-cart button {
  background-color: #099e43;
  color: #fff;
  padding: 12px 0;
  border-radius: 4px;
  display: block;
  font-size: 15px;
  font-family: "markpro-medium";
  width: 100%;
  border: none;
}

.customization {
  display: flex;
  justify-content: space-around;
  max-width: 406px;
  /* margin-left: auto; */
  width: 100%;
  padding-top: 20px;
  margin-bottom: 60px;
}
.customization :hover {
  color: white;
}

.get-help {
  background: #fff;
  color: #222f8e;
  border-radius: 4px;
}

.get-help:hover {
  color: #fff;
  background: #222f8e;
}

.get-help a {
  padding: 8px 19.2px;
  display: block;
  border-radius: 4px;
  font-size: 15px;
  font-family: "markpro-medium";
  border: 1px solid #212f8e;
  color: #222f8e;
}

.customize a {
  background-color: #fff;
  color: #212f8e;
  display: block;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 15px;
  font-family: "markpro-medium";
  border: 1px solid #212f8e;
}

.activeViews a {
  background: #222f8e;
  color: #fff;
  display: block;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 15px;
  font-family: "markpro-medium";
  border: 1px solid #212f8e;
}
.activeViews {
  color: #fff;
}

.activeViews a :hover {
  background: #fff;
  color: #222f8e;
  display: block;
  border-radius: 4px;
  font-size: 15px;
  font-family: "markpro-medium";
  border: 1px solid #212f8e;
}

/* tabs */

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #151a1e;
  background-color: transparent;
  border-color: transparent;
  font-family: "markpro-medium";
  font-size: 18px;
}
.navigation-thumbs {
  display: flex !important;
  /* flex-basis: 25%; */
}
.navigation-thumbs img {
  height: 70px;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: isolate;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #089e42 !important;
  border-radius: 0;
  background-color: transparent;
  border-left: none;
  color: #151a1e;
  border-right: none;
  border-top: none;
}

.nav-tabs .nav-link {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding-bottom: 13px;
  border-bottom: 2px solid transparent;
}

.product_description p {
  margin-top: 20px;
  font-size: 17px;
  font-family: "markpro-book";
  color: #151a1e;
}

.pop-model .btn-close:focus {
  box-shadow: none;
}

.pop-model .modal-header .btn-close {
  text-align: right;
  margin-top: -35px;
  margin-bottom: 20px;
  padding-right: 30px;
}

.filter-section {
  display: none;
}

.market-heading-inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Media Querry */

@media all and (max-width: 1399px) {
  .slider-container {
    width: 280px;
  }
}
@media all and (max-width: 1240px) {
  .customization {
    max-width: 398px;
  }
}

@media all and (max-width: 1199px) {
  .links-btn {
    text-align: center;
    width: 100%;
    max-width: 355px;
  }
  .customization {
    max-width: 100%;
    justify-content: start;
  }

  .customize {
    margin-right: 20px;
  }
}

@media all and (max-width: 1024px) {
  .links-btn {
    max-width: 248px;
  }

  .products .accordion-button {
    font-size: 18px;
  }

  .products .accordion-item:first-of-type .accordion-button {
    font-size: 18px;
  }

  .products .accordion-body {
    padding: 14px 0;
  }
}
@media all and (max-width: 1000px) {
  .plan-option .form-check.form-check-inline {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 991px) {
  .search input {
    max-width: 100%;
  }
  .main-content-inner .col-lg-4.col-md-12 {
    padding-right: calc(var(--bs-gutter-x) / 2);
    margin-bottom: 20px;
  }
  .logedin-nav .navbar-collapse {
    top: 70px;
  }
  .filter-details .form-select {
    max-width: 520px;
    width: 100%;
  }

  .filter-details label {
    margin: 20px 0 0px 0;
    max-width: 70px;
    width: 100%;
  }

  .search-area {
    display: block;
  }

  .filter-details {
    justify-content: flex-start;
  }

  .product-pagination nav {
    max-width: 90%;
  }

  .products .accordion-item:first-of-type .accordion-button,
  .products .accordion-item .accordion-button {
    font-size: 18px;
  }

  .links-btn {
    max-width: 100%;
  }

  .quantity-outer-wrap {
    margin-bottom: 20px;
  }

  .cart-btn {
    display: block;
  }

  .quantity-outer-wrap input.product-qty {
    max-width: 100%;
  }

  .customization {
    max-width: 100%;
    justify-content: start;
    display: block;
  }

  .customize {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .qty-input {
    margin-right: 0;
  }

  .get-help a {
    text-align: center;
  }
}

@media all and (max-width: 767px) {
  .products div#accordionExample {
    margin: 10px 0 40px;
    padding: 0;
    display: none;
  }
  .dashboard-head-wrap {
    top: 61px;
  }
  .logedin-nav .navbar-collapse {
    top: 61px !important;
  }
  .profile-select-menu-mb .MuiPopover-paper {
    top: 126px !important;
  }
  .profile-select-menu-mb-top .MuiPopover-paper {
    top: 61px !important ;
  }

  .product-details {
    text-align: center;
  }

  .pd {
    padding: 0;
  }

  .product-pagination nav {
    max-width: 76%;
  }

  .product-detail {
    margin-top: 50px;
  }

  .pop-model .modal-content {
    padding: 47px 30px 67px 30px;
  }

  .product-img-single {
    max-width: 90%;
    margin: 0 auto;
  }

  .product_description p {
    text-align: left;
  }

  .filter-section {
    display: block;
    float: right;
  }
}

@media all and (max-width: 575px) {
  .profile-select-menu-mb-top .MuiPopover-paper {
    top: 48px !important;
  }
  .dashboard-head-wrap {
    top: 42px !important;
  }
  .profile-select-menu-mb .MuiPopover-paper {
    top: 114px !important;
  }
  .logedin-nav .navbar-collapse {
    top: 48px !important;
  }
}

@media all and (max-width: 475px) {
  .product-pagination nav {
    max-width: 100%;
  }

  .product-pagination nav {
    padding: 10px 0 0;
  }

  footer.article-padd.inner {
    margin-top: 60px;
  }

  .page-link {
    margin: 0 10px 0 0;
  }

  li.page-item.page a {
    padding: 2px 20px;
  }

  .page-item span.total-pages {
    padding: 0 20px 0 10px;
  }

  .product-detail h1 {
    font-size: 20px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    padding: 0;
    margin-right: 20px;
    font-size: 16px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    padding: 0;
    margin-right: 20px;
    font-size: 16px;
  }

  .product-thumb-images .owl-item {
    padding: 7px 9px;
  }

  .product-thumb-single {
    padding: 0;
  }

  .product-detail-images {
    /* height: 290px; */
  }

  .slider-container .scale ins {
    left: -8px !important;
  }

  .pop-model .modal-content {
    padding: 47px 30px 47px 30px;
  }

  .product-detail-images .owl-dots {
    display: none;
  }
}

@media all and (max-width: 320px) {
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    padding: 0;
    margin-right: 10px;
    font-size: 16px;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    padding: 0;
    margin-right: 10px;
    font-size: 16px;
  }

  .product-detail-images img {
    width: 100% !important;
    padding: 0;
  }

  .product-detail-images {
    height: 236px;
  }
}

/*==============================================================================================================================================
                                                  @@@ Marketplace css End @@@ 
===============================================================================================================================================*/

/*==============================================================================================================================================
                                                    @@@ Ecommerce css starts @@@ 
===============================================================================================================================================*/

header.inr-head {
  background-color: #f7f8fa;
}

.banner-area {
  background-image: url(../assets/images/ecommerce-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 110px 0 110px 0;
  text-align: center;
  margin-top: 60px;
}

.banner-txt h1 {
  font-family: "caros-bold";
  font-size: 40px;
  line-height: 55px;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 24px;
}

.banner-txt p {
  font-size: 24px;
  line-height: 35px;
  font-family: "markpro-book";
  max-width: 660px;
  margin: 0 auto;
}

/* Sidebar */

.ecom-menu-sticky.is-affixed {
  top: -15px !important;
}

.card.ecom-menu-card {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgb(14 30 37 / 12%);
  padding: 34px 0;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 260px;
  position: -webkit-sticky;
  position: sticky;
  top: 200px;
}
.ecom-menu-card ul {
  padding-left: 0;
}

.card.ecom-menu-card ul li a {
  font-family: "markpro-book";
  font-size: 16px;
  line-height: 24px;
  color: #151a1e;
  border-left: 3px solid transparent;
  padding-left: 20px;
  text-decoration: none;
}

.card.ecom-menu-card ul li {
  margin-bottom: 32px;
}

.card.ecom-menu-card ul li:last-child {
  margin-bottom: 0;
}

.card.ecom-menu-card ul li a.active {
  color: #262635;
  border-left: 3px solid #4361cd;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-family: "markpro-medium";
}

.process-text-wrap.txt h1 {
  font-family: "caros-bold";
  font-size: 32px;
  margin-bottom: 32px;
}

.process-text-wrap.txt p {
  font-size: 16px;
  font-family: "markpro-book";
  margin-bottom: 24px;
}

.process-text-wrap.txt ul li {
  margin-bottom: 24px;
  position: relative;
  padding-left: 36px;
  font-family: "markpro-bold";
  font-size: 16px;
}
.process-text-wrap ul {
  padding-left: 0;
}

.process-text-wrap.txt ul li::before {
  position: absolute;
  background-image: url(../assets/images/list-marker.svg);
  background-repeat: no-repeat;
  width: 17px;
  height: 16px;
  content: "";
  top: 3px;
  left: 4px;
}

.automation-txt h2 {
  font-size: 16px;
  font-family: "markpro-bold";
  margin-bottom: 16px;
}

.automation-left-content .inner-most-automation.automation-txt p:last-child {
  margin-bottom: 0;
}

.autmation-wrap {
  display: flex;
  align-items: center;
}

.process-wrap {
  margin-bottom: 80px;
}

.automation-right-content {
  margin-top: 90px;
}

.management-des {
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 40px;
}

.automation-right-content ul li {
  background-color: #fff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
  border-radius: 4px;
  padding: 21px;
  position: relative;
}

.automation-right-content ul li::before {
  top: 25px !important;
  left: 12px !important;
}

.analytics-inr img {
  margin-right: 20px;
}

.management-inner {
  background-color: #fff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
  padding: 40px;
  margin-bottom: 10px;
}

.management-inner p:last-child {
  margin-bottom: 0;
}

.analytics-inr {
  display: flex;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 38px 30px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 8%);
  border-radius: 4px;
}

.process-text-wrap.txt.config {
  padding: 0;
}

.analytics-inr p {
  margin-bottom: 0 !important;
}

.management-des p:last-child {
  margin-bottom: 0;
}

.automation-right-content ul li:nth-child(even) {
  margin-left: -67px;
  max-width: 240px;
}

section.augmented .col-md-6 {
  display: flex;
  justify-content: center;
}

.augmented-txt.process-text-wrap.txt h1,
.augmented-txt.process-text-wrap.txt p,
.augmented-txt.process-text-wrap.txt ul li {
  color: #fff;
}

section.augmented {
  background-color: #13214a;
  padding: 80px;
  margin-top: 116px;
}

.automation-inner-img h1 {
  position: absolute;
  top: 70px;
  left: 35px;
}

.automation-inner-img {
  position: relative;
}
.spy-active {
  color: #262635 !important;
  border-left: 3px solid #4361cd !important;
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  font-family: "markpro-medium";
  font-weight: bolder;
}
.is-current {
  color: #262635 !important;
  border-left: 3px solid #4361cd !important;
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  font-family: "markpro-medium";
  font-weight: bolder;
}

.automation-inner-img h1 {
  color: #fff;
}

.process-text-wrap.txt {
  padding: 80px 0;
}

.process-text-wrap.txt.first {
  padding: 0;
}

.automation-inner-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.automation-left-content {
  width: 100%;
  max-width: 550px;
  margin-right: auto;
  padding-top: 32px;
}

.ecom-layout {
  display: grid;
  grid-template-columns:
    [full-start] minmax(2rem, 1fr)
    [menu-start] 15rem [menu-end] 10rem [content-start] minmax(1rem, 56rem)
    [content-end] minmax(2rem, 1fr) [full-end];
  margin: 80px 0;
}

.ecom-menu {
  grid-column: menu;
  grid-row: 1/10;
}
.ecom-menu .ecom-menu-sticky {
  position: sticky;
  top: 1em;
}
.flexibility-main.ecom-layout-content {
  grid-row: 1;
  padding: 0 0 80px 0;
}
.ecom-layout-content-bg {
  background-color: #f2f5f8;
  grid-column: full;
}
.ecom-layout-content {
  grid-column: content;
}
.automation-bg-main,
.automation-bg {
  grid-row: 2;
}
.analytics-bg-main,
.analytic-bg {
  grid-row: 4;
}

/* Media Querry */

@media all and (max-width: 1240px) {
  .automation-inner-content {
    display: block;
  }

  .automation-right-content {
    padding-top: 90px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .automation-left-content {
    max-width: 100%;
  }

  .automation-right-content ul li:nth-child(even) {
    margin-left: -80px;
    max-width: 100%;
  }

  .analytics-inr {
    padding: 25px 30px;
  }
}

@media all and (max-width: 992px) {
  .ecom-layout {
    display: block;
    margin: 40px 0;
  }

  .automation-bg-main,
  .automation-bg {
    background-color: #f7f8fa;
  }

  .ecom-menu {
    display: none;
  }

  .flexibility-main.ecom-layout-content {
    padding: 0 0 40px 0;
  }

  .automation-right-content ul li:last-child {
    margin-bottom: 0;
  }

  .process-text-wrap.txt {
    padding: 40px 15px;
  }

  .process-text-wrap.txt.first {
    padding: 0 15px;
  }

  .automation-right-content {
    padding-top: 40px;
  }

  .analytics-bg-main,
  .analytic-bg {
    background-color: #f7f8fa;
  }

  .process-text-wrap.txt h1 {
    font-size: 22px;
    margin-bottom: 22px;
  }

  .process-text-wrap.txt p {
    font-size: 16px;
    margin-bottom: 15px;
  }

  section.augmented .col-md-6 {
    justify-content: start;
  }

  .banner-area {
    padding: 80px 0;
  }
}

@media all and (max-width: 768px) {
  .process-text-wrap.txt h1 {
    font-size: 22px;
    margin-bottom: 12px;
  }
  .accordion-heads {
    font-family: "caros-med";
    font-size: 18px;
  }
  .app-equip-accord .MuiAccordion-root h2 {
    font-family: "caros-med";
    font-size: 18px;
  }
  .app-equip-accord .MuiAccordion-root h2 {
    font-size: 18px !important;
  }
  .subhead-components {
    font-size: 14px;
  }
  section.configutaor-sec .container,
  section.augmented .container {
    max-width: 100%;
  }

  section.augmented {
    padding: 40px 0;
    margin-top: 30px;
  }

  .banner-txt h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .banner-txt p {
    font-size: 18px;
    line-height: 25px;
  }
}

@media all and (max-width: 570px) {
  .automation-left-content {
    padding-top: 10px;
  }
  .logedin-nav .navbar-brand {
    order: 2;
  }
  .config-login-toggler {
    /* order: 1; */
  }
  .automation-inner-img h1 {
    position: relative;
    top: unset;
    left: unset;
    padding-top: 20px;
    color: #151a1e;
  }

  .automation-right-content ul li:nth-child(even) {
    margin-left: 0;
  }

  .banner-area {
    padding: 60px 0;
  }
}
.makeStyles-drawerHeader-8 {
  display: none !important;
}
@media (min-width: 0px) and (orientation: landscape) {
  .makeStyles-drawerHeader-8 {
    display: none !important;
  }
}

/*==============================================================================================================================================
                                                    @@@ Ecommerce css END @@@ 
===============================================================================================================================================*/
