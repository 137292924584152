.table-image thead,
td,
th {
  border: 0;
  color: #666;
  font-size: 10px;
  color: black;
  /* padding: 20px !important; */
}

.table-image td,
th {
  vertical-align: middle;
  text-align: center;
}
.table-image .qty {
  /* max-width: 2rem; */
}

.table-image .price {
  margin-left: 1rem;
}

.table-image .modal-footer {
  padding-top: 0rem;
}
.cart-modal {
  max-width: 50% !important;
}

.quantity .form-control {
  font-size: 12px;
  color: black;
  width: 40%;
}

.action-del {
  cursor: pointer;
  color: red;
}
td {
  white-space: normal !important;
}
