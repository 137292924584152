.cart-badge .badge-circle {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0 !important;
  width: 15px !important;
  height: 15px !important;
  font-size: 10px !important;
  font-weight: 600;
  position: absolute;
  font-family: markpro;
}
.nav-link-icon {
  font-family: markpro !important;
}
.navbar-toggler {
  border: 1px solid green !important;
}
.navbar-toggler-icon {
  background-image: url(../../assets/images/toggler.svg);
}
.navbar-toggler:focus {
  box-shadow: none;
}
.nav-item .nav-link-icon {
  color: #151a1e;
  font-family: markpro-medium;
  font-size: 15px;
  text-decoration: none;
}
.nav-item .nav-link-icon:hover {
  color: #099e43;
  font-family: markpro-medium;
  font-size: 15px;
}
.nav-item .nav-link-icon span {
  font-family: markpro-medium;
  font-size: 15px;
}
.nav-link-icon-active {
  color: #099e43 !important;
  font-family: markpro-medium;
  font-size: 15px;
  text-decoration: none;
}
