.app-simple.light {
  --app-background: #ffffff;
  --app-color: #585858;
  --app-avatar-background: #f8f6fd;
  --app-info-color: #9b9b9b;
  --app-info-border: #d7e0e9;
  --app-chat-background: #f0f3f7;
  --app-people-color: gray;
  --app-people-active-color: #5babfc;

  .pn-msg-input {
    --msg-input__background: transparent;
    --msg-input__send__background: #5babfc;
  }

  .pn-msg-list--light {
    --msg-list__background: transparent;
    --msg--hover__background: #e5e8ec;
  }
}
.pn-msg-input__textarea {
  height: 20px !important;
  overflow-y: scroll;
  line-height: 19px;
}

.app-simple {
  background: var(--app-background);
  color: var(--app-color);
  display: flex;

  .pn-msg-input {
    --msg-input__send__borderRadius: 25px;
    --msg-input__send__padding: 10px 20px;
    --msg-input__textarea__borderRadius: 25px;
    --msg-input__textarea__padding: 12px 24px;
  }

  .pn-channel-list {
    --channel__description__display: none;
    --channel__name__fontSize: 13px;
    --channel__name__fontWeight: 400;
    --channel__padding: 12px 24px;
  }

  .pn-member-list {
    --member__padding: 9px 0;
    --member__name__margin: 8px 0 0;
    --member__title__display: none;
    --member--hover__background: transparent;
  }

  .pn-msg-list {
    --member__padding: 9px 0;
    --member--hover__background: transparent;
  }
  .remove-icon {
    position: absolute;
    right: 11px;
    top: 8px;
  }

  .channels-heading {
    font-weight: bold;
    font-size: 18px;
    color: #585858;
  }
  .pn-msg__bubble {
    word-break: break-all !important;
  }
  .user-name {
    font-size: 16px;
    font-weight: bold;
    color: #585858;
  }

  .user {
    display: flex;
    padding: 46px 24px 20px;

    h4 {
      flex-grow: 1;
      margin: 8px 0;
    }

    img {
      background: var(--app-avatar-background);
      display: block;
      margin: 0 24px 0 0;
      width: 36px;
      height: 36px;
      border-radius: 36px;
    }
  }

  .chatinfo {
    padding: 24px 24px 0;
    border-bottom: 1px solid lightgrey;

    h4 {
      margin: 0 0 2px;
    }

    small {
      color: var(--app-info-color);
    }

    hr {
      background-color: var(--app-info-border);
      border: 0;
      height: 1px;
      margin: 18px 0 0;
    }
  }

  .chat {
    position: fixed;
    background: var(--app-chat-background);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 30px 30px 30px 0;
    padding-bottom: 30px;
    height: 49vh;
    max-width: 316px;
    bottom: -26px;
    right: 2%;
    z-index: 10;
  }

  .members {
    background: var(--app-background);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    transform: translateX(310px);
    transition: 0.3s ease-in-out;
    width: 0;

    &.shown {
      transform: none;
      width: 310px;
    }

    h4 {
      margin-top: 54px;
    }

    .close {
      margin-right: 24px;
    }
  }

  .close {
    cursor: pointer;
    display: none;
    float: right;
  }

  .hamburger {
    display: none;
    float: left;
    font-size: 24px;
    margin-right: 15px;
  }

  .pn-msg__avatar {
    display: none;
  }

  @media (max-width: 950px) {
    .channels,
    .members.shown {
      width: 200px;
    }
  }

  @media (max-width: 700px) {
    .user {
      padding-top: 24px;
    }

    .close {
      display: inline-block;
    }

    .chat {
      margin: 5px;
    }

    .hamburger {
      display: inline;
    }

    .members.shown {
      height: 100%;
      padding: 24px;
      position: absolute;
      z-index: 10;
      width: 100%;

      h4 {
        margin-top: 0;
      }

      h4 span {
        margin-right: 48px;
      }
    }
  }
}
