.app-equip-accord .MuiAccordion-root {
  border: 0px !important;
  border-bottom: 1px solid #e0e0e0 !important;
  margin: 0 !important;
  box-shadow: none;
  background: #f7f8fa !important;
}
.app-equip-accord .MuiAccordion-root h2 {
  font-size: 22px;
}
.marketPlace-filters-head {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 35px;
  // font-family: c;
}
.app-equip-accord .MuiAccordion-root::before {
  background: none !important;
}
.app-equip-accord .MuiAccordion-root .MuiAccordionSummary-root {
  padding-left: 0px !important;
  padding-right: 16px;
}
