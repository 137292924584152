body {
  background-color: #f3f3f3;
}

.userinfo-wrapper {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  margin-top: 50px;
  width: auto;
  display: flex;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
}

.userinfo-wrapper .left {
  width: 35%;
  padding: 30px 25px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
  color: #fff;
}

.userinfo-wrapper .left img {
  border-radius: 5px;
  margin-bottom: 10px;
}

.userinfo-wrapper .left h4 {
  margin-bottom: 10px;
}

.userinfo-wrapper .left p {
  font-size: 12px;
}

.userinfo-wrapper .right {
  width: 65%;
  background: #fff;
  padding: 30px 25px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.userinfo-wrapper .right .info,
.userinfo-wrapper .right .projects {
  margin-bottom: 25px;
}

.userinfo-wrapper .right .info h3,
.userinfo-wrapper .right .projects h3 {
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  color: #353c4e;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.userinfo-wrapper .right .info_data,
.userinfo-wrapper .right .projects_data {
  display: flex;
  justify-content: space-between;
}

.userinfo-wrapper .right .info_data .data,
.userinfo-wrapper .right .projects_data .data {
  width: 45%;
}

.userinfo-wrapper .right .info_data .data h4,
.userinfo-wrapper .right .projects_data .data h4 {
  color: #353c4e;
  margin-bottom: 5px;
}

.userinfo-wrapper .right .info_data .data p,
.userinfo-wrapper .right .projects_data .data p {
  font-size: 13px;
  margin-bottom: 10px;
  color: #919aa3;
}

.userinfo-wrapper .social_media ul {
  display: flex;
}

.userinfo-wrapper .social_media ul li {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  line-height: 45px;
}

.userinfo-wrapper .social_media ul li a {
  color: #fff;
  display: block;
  font-size: 18px;
}
